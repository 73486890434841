<ng-template let-course="course" #headerTpl>
  <div class="header-panel rounded shadow-sm">
    <div class="row header">
      <div class="col-12 col-md-8">
        <h4 class="mt-1">
          {{course.title}}

          <span class="m-2" [routerLink]="['/course/learn/', course.customer_course_id]">
            <fa-icon icon="home" class="cursor-pointer font-medium-2 text-primary"></fa-icon>
          </span>
        </h4>
      </div>

      <div class="col-12 col-md-4 align-self-center mt-2 mt-sm-0">
        <app-course-progress-bar height="25" progress_bar_id="overall_progress">
        </app-course-progress-bar>
      </div>
    </div>
  </div>
</ng-template>

<!--------------- Template --------------->
<div style="height: 100vh;">
  <ng-sidebar-container style="height: 100vh;">
    <ng-sidebar #sidebar [(opened)]="_opened" [mode]="_sideBarMode" [closeOnClickOutside]="_getCloseOnClickOutside()"
      [autoCollapseWidth]="1300">

      <app-course-content-menu [course_section_id_current]="course_section_id_current"
        [customer_course_id]="course.customer_course_id" [sidebar]="sidebar">
      </app-course-content-menu>
    </ng-sidebar>

    <div ng-sidebar-content class="pt-2 mx-2 mx-md-4">
      <div class="pb-2">
        <div class="mb-2">
          <fa-icon icon="bars" class="py-2 cursor-pointer font-medium-2" (click)="_toggleOpened()"></fa-icon>
        </div>

        <ng-container *ngTemplateOutlet="headerTpl; context:{course: course}"></ng-container>
      </div>
      <router-outlet (activate)="routerActivated($event)"></router-outlet>
    </div>
  </ng-sidebar-container>
</div>