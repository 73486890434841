import { Component, Input } from '@angular/core';
import { DisplayErrorsService } from './display-errors.service';

@Component({
  selector: 'app-display-errors',
  templateUrl: './display-errors.component.html',
})
export class DisplayErrorsComponent {
  @Input() errors: any;

  constructor(
    private errorService: DisplayErrorsService,
  ) {
    this.errorService.onError.subscribe(
      (errorData) => {
        console.log(errorData)
        this.errors = errorData.errors;
      });
  }

  public keepOriginalOrder = (a, b) => a.key;

}
