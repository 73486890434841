import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CourseService } from '@app/pages/course/shared/course.service';
@Component({
  selector: 'app-course-progress-bar-round',
  templateUrl: './course-progress-bar-round.component.html',
  styleUrls: ['./course-progress-bar-round.component.scss'],
})

export class CourseProgressBarRoundComponent {
  @Input() completion_level: number;
  @Input() locked: boolean;
  @Input() height = '30px';

  constructor(
    private courseService: CourseService,
  ) {
  }

  getProgressColor() {
    return this.courseService.getCourseProgressColor(this.completion_level);
  }

  getShowImage() {
    let showImg = false;
    if (this.locked || this.completion_level >= 100) {
      showImg = true;
    }

    return showImg;

  }
  getImageSrc() {
    let imgSrc = '';
    if (this.locked) {
      imgSrc = 'assets/img/icons/course/lock.svg';
    } else if (this.completion_level >= 100) {
      imgSrc = 'assets/img/icons/course/thumbs-up.svg';
    }

    return imgSrc;
  }
}
