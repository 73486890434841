import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import { ApiConf } from './api-conf';
import { apiConfs } from './api-confs-setup';
import { UtilService } from '@app/shared/services/util.service';

@Injectable({ providedIn: 'root' })
export class APIDetailResolver {

  constructor(
    private util: UtilService,
  ) { }

  getApiDetails(url?: string, routeParams?: any): ApiConf {
    url = url ? url : window.location.pathname;

    // cloning so that value is not overwritten in the main obj apiConfs
    // causing some value retention from previous route (from detail to list it stores id)
    const apiConf: ApiConf = lodash.clone(this.getApiConfByUrl(url));

    const id = lodash.get(routeParams, apiConf.idName);
    if (id) {
      apiConf.id = id;
    }

    let queryParams = lodash.mapValues(apiConf.queryParamsConf, (val, paramName) => {
      let paramVal = lodash.get(routeParams, paramName);
      if (!paramVal) {
        paramVal = this.util.getUrlParam(paramName);
      }
      return paramVal ? paramVal : null;
    });

    queryParams = lodash(queryParams).omitBy(lodash.isNull).value();
    if (!lodash.isEmpty(queryParams)) {
      apiConf.queryParams = queryParams;
    }

    return apiConf;
  }

  getApiConfByUrl(url?: string) {
    url = url ? url : window.location.pathname;

    // ex: url = course/list
    // apiConfFound = course
    url = '/' + url;

    const apiConfFound = lodash.filter(apiConfs, (apiConf) => {
      return lodash.every(apiConf.urlFragments, (urlFragment) => {
        return lodash.includes(url, urlFragment);
      });
    });

    if (apiConfFound.length) {
      return apiConfFound[0];
    }

    return false;
  }


}
