import { ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import * as lodash from 'lodash';
import { CourseContentMenuService } from '../../shared/course.content.menu.service';

@Component({
  selector: 'app-course-content-menu',
  templateUrl: './course-content-menu.component.html',
  styleUrls: ['./course-content-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CourseContentMenuComponent {
  @Input() course: any;
  @Input() course_section_id_current: number;
  @Input() customer_course_id: number;
  @Input() sidebar: any;

  courseSections: any;

  constructor(
    private menuService: CourseContentMenuService,
    private changeDetectRef: ChangeDetectorRef,
  ) {
    this.menuService.menuDataChange.subscribe(
      (data) => {
        // these steps updates course content menu
        if (data.course) {
          this.course = data.course;
          this.courseSections = this.course.sections;
        }
        if (data.course_section_id) {
          this.course_section_id_current = data.course_section_id;
        }

        const course_content = data?.course_content;

        if (course_content) {
          const course_content_id = data.course_content.course_content_id;
          const course_section_id = data.course_section_id;
          lodash.map(this.courseSections, (courseSection) => {
            if (courseSection.course_section_id === course_section_id) {
              lodash.map(courseSection.contents, (courseContent) => {
                if (courseContent.course_content_id === course_content_id) {
                  courseContent.completed = course_content.completed;
                }
              });
            }
          });
        }

        this.changeDetectRef.detectChanges();
      }
    );
  }

  _closeMenu() {
    this.sidebar.close();
  }
}
