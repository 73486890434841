<div class="modal-header">
  <h4 class="modal-title" [innerHTML]="title"></h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngTemplateOutlet="body"></ng-container>
</div>
<div class="modal-footer">
	<ng-container *ngTemplateOutlet="footer || defaultFooterTpl">
	</ng-container>
</div>

<ng-template #defaultFooterTpl>
  <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
</ng-template>

