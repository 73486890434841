import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { CourseSectionComponent } from '../../course-section/course-section.component';
import { CourseContentMenuService } from '../../shared/course.content.menu.service';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CourseProgressChangeData } from '@app/shared/components/course-progress-bar/course-progress-change-data.model';
import { CourseProgressBarService } from '@app/shared/components/course-progress-bar/course-progress-bar.service';

@Component({
  selector: 'app-container',
  templateUrl: './course-learn-container.component.html',
  styleUrls: ['./course-learn-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CourseLearnContainerComponent extends BaseComponent implements OnInit, OnDestroy {
  id: number;
  course: any;
  course_section_id_current: number = null;

  _opened = true;
  _sideBarMode = 'push';
  mqAlias = '';

  watcher: Subscription;

  constructor(
    mediaObserver: MediaObserver,
    actRoute: ActivatedRoute,
    private cpbs: CourseProgressBarService,
  ) {
    super(actRoute);

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.mqAlias = change.mqAlias;
      // this._sideBarMode = change.mqAlias === 'md' || change.mqAlias === 'lg' ? 'push' : 'over';
    });
  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }

  ngOnInit() {
    super.ngOnInit();

    this.course = this.row;

    const ccms = AppInjector.get(CourseContentMenuService);
    const args = {course: this.course, course_section_id: null};
    ccms.menuDataChange.emit(args);

    // putting inside the setTimeout because the progress bar component is loading after this component
    setTimeout(() => {
      const eventData: CourseProgressChangeData = {
        completion_level: this.course.course_completion_level,
        progress_bar_id: 'overall_progress',
      };
      this.cpbs.progressChange.emit(eventData);
    }, 0);
  }

  routerActivated(routerComponent) {
    // the returned routerComponent will be the component class loaded in the router-outlet
    if (routerComponent instanceof CourseSectionComponent) {
      this.course_section_id_current = routerComponent.actRoute.snapshot.params.id;
    }
  }

   _toggleOpened(): void {
    this._opened = !this._opened;
  }

  _getCloseOnClickOutside() {
    return this.mqAlias === 'md' || this.mqAlias === 'lg' ? false : true;
  }
}
