import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DisplayErrorsService } from '@app/shared/forms/display-errors.component/display-errors.service';
import * as lodash from 'lodash';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UiUtilService } from '../ui-util.service';
import { ApiConf } from './api-conf';
import { APIDetailResolver } from './api-details';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private uiUtil: UiUtilService,
    private route: ActivatedRoute,
    private apiDetailResolver: APIDetailResolver,
    private errorService: DisplayErrorsService,

  ) { }

  public getData(apiName: string, id?: string,
    exp?: {
      apiConf?: ApiConf,
      action?: string,
      queryParams?: {}
    }) {
    let apiUrl = `/cpapi/${apiName}/`;
    let httpParams = new HttpParams();

    const action = exp?.action ? exp.action : '';

    if (id) {
      apiUrl = `${apiUrl}${id}/`;
    }

    const queryParams = lodash.get(exp, 'apiConf.queryParams');
    if (queryParams) {
      lodash.map(queryParams, (val, key) => {
        httpParams = httpParams.set(key, val)
      });
    }

    if (action) {
      httpParams = httpParams.append('action', action);
    }
    const queryParamsApi = lodash.get(exp, 'queryParams');
    if (queryParamsApi) {
      lodash.map(queryParamsApi, (val, key) => {
        httpParams = httpParams.set(key, val)
      });
    }

    const paramsStr = httpParams.toString();
    apiUrl = apiUrl + (paramsStr ? '?' + paramsStr : '');

    return this.http.get<any>(apiUrl).pipe(
      map(response => {
        return response;
        // return this.processData(response, modelType);

      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  processData<T>(data: T, modelType: string): T {
    // const returnData: modelType[];
    // implementation to be done here soon
    return data;

  }

  public add(params?: {
    data?: any[],
    apiName?: string,
    id?: string,
    action?: string,
    queryParams?: any,
    subscriberFn?: any,
    callbackFn?: Function,
    showToastMsg?: boolean,
    toastMsg?: string,
    toastMsgError?: string,
  }) {

    this.uiUtil.showSpinner();

    let apiDetails = null;
    if (!params.apiName) {
      apiDetails = this.apiDetailResolver.getApiDetails();
    }

    const data = params?.data ? params.data : {};
    const apiName = params?.apiName ? params.apiName : apiDetails?.apiName;
    const id = params?.id ? params.id : apiDetails?.id;
    const action = params?.action ? params.action : '';
    const showToastMsg = lodash.has(params, 'showToastMsg') ? params.showToastMsg : true;
    const toastMsg = lodash.has(params, 'toastMsg') ? params.toastMsg : 'Success!!';
    const toastMsgError = lodash.has(params, 'toastMsgError') ? params.toastMsgError : 'Error occurred...';
    const subscriberFn = params?.subscriberFn;

    params.queryParams = lodash(params.queryParams).omitBy(lodash.isUndefined).omitBy(lodash.isNull).value();

    let httpParams = new HttpParams({
      fromObject: params.queryParams
    });

    if (action) {
      httpParams = httpParams.append('action', action);
    }
    let apiUrl = `/cpapi/${apiName}/`;
    if (id) {
      apiUrl = `${apiUrl}${id}/`;
    }

    const paramsStr = httpParams.toString();
    apiUrl = apiUrl + (paramsStr ? '?' + paramsStr : '');

    const observable = this.http.post(apiUrl, data);
    if (subscriberFn) {
      return observable.subscribe(subscriberFn);
    }

    return observable.subscribe((returnData: any) => {
      this.uiUtil.hideSpinner();
      const status = returnData.status;

      if (showToastMsg) {
        if (status === 'error') {
          this.uiUtil.showToast({ body: toastMsgError, type: 'error' });
          this.errorService.onError.emit(returnData.data);
        } else {
          if (toastMsg) {
            this.uiUtil.showToast({ body: toastMsg });
          }
        }
      }

      if (params.callbackFn) {
        params.callbackFn.call(this, returnData);
      }
    });
  }

  // alias function to the "add" function
  public post(params?: {
    data?: any[],
    apiName?: string,
    id?: string,
    action?: string,
    queryParams?: any,
    subscriberFn?: any,
    callbackFn?: Function,
    showToastMsg?: boolean,
    toastMsg?: string,
    toastMsgError?: string,
  }) {
    return this.add(params);
  }

  public save(params?: {
    data?: any,
    apiName?: string,
    id?: string,
    action?: string,
    queryParams?: any,
    subscriberFn?: any,
    callbackFn?: Function,
    showToastMsg?: boolean,
    toastMsg?: string,
    toastMsgError?: string,
    showSpinner?: boolean,
  }) {

    let apiDetails = null;
    if (!params.apiName) {
      apiDetails = this.apiDetailResolver.getApiDetails();
    }

    const data = params?.data ? params.data : {};
    const apiName = params?.apiName ? params.apiName : apiDetails.apiName;
    const id = params?.id ? params.id : apiDetails?.id;
    const action = params?.action ? params.action : '';
    const showToastMsg = lodash.has(params, 'showToastMsg') ? params.showToastMsg : true;
    const toastMsg = lodash.has(params, 'toastMsg') ? params.toastMsg : 'Saved...';
    const toastMsgError = lodash.has(params, 'toastMsgError') ? params.toastMsgError : 'Error occurred...';
    const showSpinner = lodash.has(params, 'showSpinner') ? params.showSpinner : true;

    const subscriberFn = params?.subscriberFn;

    params.queryParams = lodash(params.queryParams).omitBy(lodash.isUndefined).omitBy(lodash.isNull).value();

    if (showSpinner) {
      this.uiUtil.showSpinner();
    }

    let httpParams = new HttpParams({
      fromObject: params.queryParams
    });
    if (action) {
      httpParams = httpParams.append('action', action);
    }

    let apiUrl = `/cpapi/${apiName}/`;
    if (id) {
      apiUrl = `${apiUrl}${id}/`;
    }

    const paramsStr = httpParams.toString();
    apiUrl = apiUrl + (paramsStr ? '?' + paramsStr : '');
    const observable = this.http.put(apiUrl, data);
    if (subscriberFn) {
      return observable.subscribe(subscriberFn);
    }

    return observable.subscribe((returnData: any) => {
      if (showSpinner) {
        this.uiUtil.hideSpinner();
      }
      const status = returnData.status;

      if (showToastMsg) {
        if (status === 'error') {
          this.uiUtil.showToast({ body: toastMsgError, type: 'error' });
          this.errorService.onError.emit(returnData.data);
        } else {
          if (toastMsg) {
            this.uiUtil.showToast({ body: toastMsg });
          }
        }
      }
      if (params.callbackFn) {
        params.callbackFn.call(this, returnData);
      }
    });
  }

  public delete(params?: {
    data?: any[],
    apiName?: string,
    id?: string,
    action?: string,
    queryParams?: any,
    subscriberFn?: any,
    callbackFn?: Function,
    showToastMsg?: boolean,
    toastMsg?: string,
  }) {

    this.uiUtil.showSpinner();

    const apiDetails = this.apiDetailResolver.getApiDetails();

    const data = params?.data ? params.data : {};
    const apiName = params?.apiName ? params.apiName : apiDetails.apiName;
    const id = params?.id ? params.id : apiDetails?.id;
    const action = params?.action ? params.action : '';
    const showToastMsg = lodash.has(params, 'showToastMsg') ? params.showToastMsg : true;
    const toastMsg = lodash.has(params, 'toastMsg') ? params.toastMsg : 'Success!!';
    const subscriberFn = params?.subscriberFn;

    params.queryParams = lodash(params.queryParams).omitBy(lodash.isUndefined).omitBy(lodash.isNull).value();

    let httpParams = new HttpParams({
      fromObject: params.queryParams
    });

    if (action) {
      httpParams = httpParams.append('action', action);
    }

    let apiUrl = `/cpapi/${apiName}/`;
    if (id) {
      apiUrl = `${apiUrl}${id}/`;
    }

    const paramsStr = httpParams.toString();
    apiUrl = apiUrl + (paramsStr ? '?' + paramsStr : '');

    const observable = this.http.delete(apiUrl, data);
    if (subscriberFn) {
      return observable.subscribe(subscriberFn);
    }

    return observable.subscribe((returnData) => {
      this.uiUtil.hideSpinner();
      if (showToastMsg) {
        this.uiUtil.showToast({ body: toastMsg });
      }
      if (params.callbackFn) {
        params.callbackFn.call(this, returnData);
      }
    });
  }

  addUpdateRowInList(row, id_name, rows) {
    let rowExisting = lodash.find(rows, (rowX) => {
      return rowX[id_name] === row[id_name];
    });

    if (!rowExisting) {
      rows.push(row);
    } else {
      rowExisting = lodash.merge(rowExisting, row);
    }
  }
}
