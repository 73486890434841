import { Routes } from '@angular/router';
import { CourseContentDetailComponent } from '@app/pages/course/course-content-detail/course-content-detail.component';
import { CourseLearnContainerComponent } from '@app/pages/course/course-learn/course-learn-container/course-learn-container.component';
import { CourseLearnComponent } from '@app/pages/course/course-learn/course-learn.component';
import { CourseSectionComponent } from '@app/pages/course/course-section/course-section.component';
import { DataResolver } from '@app/shared/services/data/data.resolver';


export const LEARN_ROUTES: Routes = [
  {
    path: 'course/learn',
    children: [
      {
        path: ':customer_course_id',
        component: CourseLearnContainerComponent,
        resolve: {
          cpData: DataResolver
        },
        children: [
          {
            path: '',
            // pathMatch : 'full',
            component: CourseLearnComponent,
          },
          {
            path: 'section/:course_section_id',
            component: CourseSectionComponent,
            resolve: {
              cpData: DataResolver
            }
          },
          {
            path: 'section/:course_section_id/content/:course_content_id',
            component: CourseContentDetailComponent,
            resolve: {
              cpData: DataResolver
            }
          },

        ]
      }
    ]
  },
];
