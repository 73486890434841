import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CourseProgressBarService } from '@app/shared/components/course-progress-bar/course-progress-bar.service';
import { CourseProgressChangeData } from '@app/shared/components/course-progress-bar/course-progress-change-data.model';
import * as lodash from 'lodash';
import { CourseContentMenuService } from '../shared/course.content.menu.service';
import { CourseService } from '../shared/course.service';

@Component({
  selector: 'app-course-section',
  templateUrl: './course-section.component.html',
  styleUrls: ['./course-section.component.scss'],
})
export class CourseSectionComponent implements OnInit {
  sectionCurrent: any;
  public course_section_id: number;
  public course: any;

  constructor(
    public actRoute: ActivatedRoute,
    private router: Router,
    private ccms: CourseContentMenuService,
    public courseService: CourseService,
    private cpbs: CourseProgressBarService,
  ) { }

  ngOnInit() {
    this.initData();
    let args = { course: this.course, course_section_id: this.course_section_id };
    this.ccms.menuDataChange.emit(args);

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.course = this.actRoute.snapshot.data.cpData.data;
        this.initData();
        args = { course: this.course, course_section_id: this.course_section_id };
        this.ccms.menuDataChange.emit(args);
      }
    });

    // putting inside the setTimeout because the progress bar component is loading after this component
    setTimeout(() => {
      const eventData: CourseProgressChangeData = {
        completion_level: this.course.course_completion_level,
        progress_bar_id: 'overall_progress',
      };
      this.cpbs.progressChange.emit(eventData);
    }, 0);
  }

  initData() {
    this.course = this.actRoute.snapshot.data.cpData.data;
    this.course_section_id = lodash.toNumber(this.actRoute.snapshot.params.course_section_id);

    this.sectionCurrent = lodash.find(this.course.sections, { course_section_id: this.course_section_id });
  }
}
