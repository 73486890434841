import { Injectable } from '@angular/core';

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
}

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _env: string;
  private _apiUrl: string;
  public isDev = false;

  get env(): string {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get cpBuyNowUrl(): string {
    return this._apiUrl + '/eng/courses/';
  }

  get cpLoginUrl(): string {
    return this._apiUrl + '/eng/login/';
  }

  get cpLogoutUrl(): string {
    return this._apiUrl + '/index.php?widget=member_login&_spAction=logout&showHTML=0';
  }

  get errorPageUrl(): string {
    return '/pages/error';
  }
  constructor() { }

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();

      if (this._env === 'dev') {
        this.isDev = true;
      }
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;

    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Dev;
      this._apiUrl = 'http://evolutioncoursemgt.localhost';

    } else if (/^evolutioncoursemgtpwa-uat.pilot.com.hk/.test(hostname)) {
      this._env = Environment.Test;
      this._apiUrl = 'https://evolutioncoursemgt.pilottest.site';

    } else if (/^app.evolution-u.com/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl = 'https://online.evolution-u.com';

    } else if (/^evolutioncoursemgtpwa.s3-website.ap-east-1.amazonaws.com/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl = 'https://online.evolution-u.com';

    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
