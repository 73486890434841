import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(
    private readonly updates: SwUpdate,
    private translate: TranslateService,
  ) {
    this.updates.available.subscribe(async (event) => {
      await this.showAppUpdateAlert();
    });
  }

  showAppUpdateAlert() {
    let title = this.translate.instant('App Update');
    let message = this.translate.instant('app.appUpdate.message');
    let cancelButtonText = '<span>' + this.translate.instant('Cancel') + '</span>';

    swal.fire({
      title: title,
      text: message,
      icon: 'success',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-primary ml-1',
      },
      cancelButtonText: cancelButtonText,
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.doAppUpdate();
      }
    });
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => {
      document.location.reload();
    });
  }
}
