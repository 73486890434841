import { RouteAcl } from './route-acl';

const routesAclConfig: RouteAcl[] = [];

// accessTo = ['Trainee', 'Account Admin', 'Manager'];

routesAclConfig.push(
  new RouteAcl({
    pathPref: '/dashboard',
  }),
  new RouteAcl({
    pathPref: '/account',
  }),
  new RouteAcl({
    pathPref: '/company', accessibleToTraineeTypes: ['Account Admin']
  }),
  new RouteAcl({
    pathPref: '/training', accessibleToCustomerTypes: ['Company']
  }),
  new RouteAcl({
    pathPref: '/employee-training', accessibleToTraineeTypes: ['Manager'], hasEmployeeAssessmentForManager: true,
  }),
  new RouteAcl({
    pathPref: '/course'
  }),
  new RouteAcl({
    pathPref: '/community', accessibleToCustomerTypes: ['Company']
  }),
  new RouteAcl({
    pathPref: '/knowledge-base',
  }),
  new RouteAcl({
    pathPref: '/message',
  }),
  new RouteAcl({
    pathPref: '/report',
  }),
);

export const routesAclConf = routesAclConfig;
