import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@app/shared/services/data/data.service';
@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(
    // making this public otherwise it doesn't reflect the change in language in the getCourseLearnBtnText function
    public translate: TranslateService,
    private dataService: DataService,
  ) { }

  getCourseLearnBtnText(row) {
    let btnText = '';
    if (row.course_completion_level === 0) {
      btnText = this.translate.instant('START LEARNING');
    } else if (row.course_completion_level > 0 && row.course_completion_level < 100) {
      btnText = this.translate.instant('CONTINUE LEARNING');
    } else {
      btnText = this.translate.instant('GO TO COURSE');
    }

    return btnText;
  }

  public getCourseProgressColor(completionLevel: number) {
    let color = '#f56464'; // red

    if (completionLevel > 70) {
      color = '#51d699';  // green
    } else if (completionLevel > 30) {
      color = '#FFBF00'; // amber
    }

    return color;
  }

  public getCourseColor(section) {
    let color = '#acacac'; // grey

    if (section.completed) {
      color = '#51d699'; // green
    } else if (!section.locked) {
      color = '#f9b224';
    }

    return color;
  }

  public getContentInfo(content) {
    let color = '#acacac'; // grey
    let icon = 'lock';

    if (content.completed) {
      color = '#51d699'; // green
      icon = 'thumbs-up';
    } else if (!content.locked) {
      color = '#f9b224';
      icon = 'lock-open';
    }

    return { color: color, icon: icon };
  }


  emailCourseCertificate(course_trainee_id, toastMsg?) {
    this.dataService.post({
      apiName: 'certificate',
      action: 'emailCourseCertificate',
      queryParams: {
        course_trainee_id: course_trainee_id,
      },
      toastMsg: toastMsg,
      callbackFn: (returnData) => { }
    });
  }

}
