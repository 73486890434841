import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppInjector } from '@app/app-injector';
import { ComponentDataService } from '@app/shared/services/component.data.service';
import { ApiConf } from '@app/shared/services/data/api-conf';
import { APIDetailResolver } from '@app/shared/services/data/api-details';
import { UiUtilService } from '@app/shared/services/ui-util.service';
import { UtilService } from '@app/shared/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';

@Component({
  selector: 'app-base',
  template: '',
})
export abstract class BaseComponent implements OnInit {
  rows: any[] = [];
  id: any;
  row: any;
  vls: any; // valuelists
  apiConf: any;
  cds: ComponentDataService;
  util: UtilService;
  uiUtil: UiUtilService;
  translate: TranslateService;

  constructor(
    public actRoute?: ActivatedRoute,
  ) {
    this.cds = AppInjector.get(ComponentDataService);
    this.util = AppInjector.get(UtilService);
    this.uiUtil = AppInjector.get(UiUtilService);
    this.translate = AppInjector.get(TranslateService);
  }

  ngOnInit(): void {
    const apiDetail = AppInjector.get(APIDetailResolver);

    const routeSnapshot = this.actRoute.snapshot;
    const cpData = routeSnapshot.data.cpData;

    if (cpData) {
      const url = this.uiUtil.getResolvedUrl(routeSnapshot);

      const apiConf: ApiConf = apiDetail.getApiDetails(url);
      const id = lodash.get(routeSnapshot.params, apiConf.idName, false);
      this.apiConf = apiConf;
      // detail mode
      if (id) {
        this.id = id;
        this.row = cpData.data;
      } else {
        // list mode
        this.rows = cpData.data;
      }

      if (cpData.vls) {
        this.vls = cpData.vls;
      }

      // set the data in the ComponentDataService to share it between components
      // (especially in the child & sibling components)
      this.cds.id = this.id;
      this.cds.row = this.row;
      this.cds.rows = this.rows;
      this.cds.vls = this.vls;
    }
  }
}
