import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  public currentUrl: string = "";
  public currentUrlNoQueryParams: string = "";

  constructor(
  ) {
  }

  setUrlDetails(url) {
    this.currentUrl = url;
    this.currentUrlNoQueryParams = url;
  }
}
