import { Component, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() title: string;
  @Input() body: TemplateRef<any>;
  @Input() footer: string;

  constructor(public activeModal: NgbActiveModal) {
    // console.log(activeModal)
  }

  close() {
    this.activeModal.close();
  }

}
