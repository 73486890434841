import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@shared/services/global.service';
import * as uuid from 'uuid';
@Injectable({ providedIn: 'root' })
export class UtilService {

  constructor(
    public global: GlobalService,
  ) {
  }

  uniqueId() {
    return uuid.v4();
  }

  convertNullToEmpty(data) {
    for (const key in data) {
      if (data[key] === null) {
        data[key] = '';
      }
    }

    return data;
  }

  getImageSrc(url) {
    url = url ? url : '/assets/img/default-photo.png';

    return url;
  }

  getUrlParam(paramName) {
    const url = this.global.currentUrl;
    let paramValue;
    if (url.includes('?')) {
      const httpPars = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpPars.get(paramName);
    }
    return paramValue;
  }

  getUrlParam2(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpPars = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpPars.get(paramName);
    }
    return paramValue;
  }

  getLangPrefix3Letter(lang) {
    let lang3Letter = '';
    if (lang === 'en') {
      lang3Letter = 'eng';
    } else if (lang === 'fr') {
      lang3Letter = 'fre';
    } else if (lang === 'es') {
      lang3Letter = 'spa';
    } else if (lang === 'pt') {
      lang3Letter = 'por';
    }

    return lang3Letter;
  }
}
