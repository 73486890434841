import * as lodash from 'lodash';

export class ApiConf {
  apiName = '';
  id = ''; // api id name
  idName = '';

  // set how to fill the queryParams from route params. ex: queryParamsConf: {course_id: 'id'}
  queryParamsConf = {};
  queryParams = {}; // query params returned from getApiDetails functions.
  urlFragments = []; // url path to test to get apiName automatically

  constructor(obj: any) {
    this.apiName = lodash.has(obj, 'apiName') ? obj.apiName : '';
    this.id = lodash.has(obj, 'id') ? obj.id : '';
    this.idName = lodash.has(obj, 'idName') ? obj.idName : '';
    this.urlFragments = lodash.has(obj, 'urlFragments') ? obj.urlFragments : [];
    this.queryParamsConf = lodash.has(obj, 'queryParamsConf') ? obj.queryParamsConf : {};
  }
}
