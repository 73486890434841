import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { TranslateModule } from '@ngx-translate/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { PipeModule } from 'app/shared/pipes/pipe.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { DynamicModule } from 'ng-dynamic-component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { CustomizerComponent } from './customizer/customizer.component';
import { VarDirective } from './directives/ng-var.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { EmbedVideo } from 'ngx-embed-video';
import { FlexLayoutModule } from '@angular/flex-layout';

// DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDirective } from './directives/topmenu.directive';

// COMPONENTS
import { ModalComponent } from './components/modal/modal.component';
import { VideoPlayerComponent} from './components/video-player/video-player.component';
import { FooterComponent } from './footer/footer.component';
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { IconsModule } from './modules/icons/icons.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { CourseProgressBarComponent } from '@app/shared/components/course-progress-bar/course-progress-bar.component';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxValidationErrorsModule } from '@xtream/ngx-validation-errors';
import { DisplayErrorsComponent } from './forms/display-errors.component/display-errors.component';
import { CourseProgressBarRoundComponent } from './components/course-progress-bar-round/course-progress-bar-round.component';
import { SidebarModule } from 'ng-sidebar';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    IconsModule,
    DynamicModule,
    NgxSpinnerModule,
    ToastrModule,
    NouisliderModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    EmbedVideo.forRoot(),
    NgCircleProgressModule,
    FlexLayoutModule,
    SidebarModule.forRoot(),
    LightboxModule,
  ],

  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    VarDirective,

    ModalComponent,
    VideoPlayerComponent,
    CourseProgressBarComponent,
    CourseProgressBarRoundComponent,
    DisplayErrorsComponent,
  ],


  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    PipeModule,
    IconsModule,
    NgbProgressbarModule,
    NgCircleProgressModule,

    DynamicModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule,
    NouisliderModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,

    ModalComponent,
    VideoPlayerComponent,
    CourseProgressBarComponent,
    CourseProgressBarRoundComponent,
    NgxValidationErrorsModule,
    DisplayErrorsComponent,
    FlexLayoutModule,
    SidebarModule,
    LightboxModule,
  ],

  providers: [
    NgbActiveModal,
  ]
})
export class SharedModule { }
