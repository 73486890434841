import { reportsInfo } from './../../pages/report/report-setup.config';
import { GlobalService } from '@shared/services/global.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { _ } from 'core-js';
import { EnvService } from '../services/env.service';
import { AuthService } from './auth.service';
import * as lodash from 'lodash';
import { routesAclConf } from '@shared/services/data/routes-acl-setup';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private envService: EnvService,
    private global: GlobalService,
  ) { }

  canActivate() {
    const isLoggedIn = this.authService.isLoggedIn();
    const trainee = this.authService.getTrainee();

    // if not logged in then redirect to login page
    if (!isLoggedIn) {
      // ex: http://localhost:4200/company/course-setting/list?retPathLang=fr&lang=fre
      const retPath = document.location.pathname;
      const queryString = document.location.search.substring(1);

      document.location.href = `${this.envService.cpLoginUrl}?retPath=${retPath}&${queryString}`;

      return false;

    } else {

      const routeAcl = lodash.find(routesAclConf, (routeConf) => {
        return lodash.startsWith(this.global.currentUrl, routeConf.pathPref);
      });

      let hasAccess = false;
      if (lodash.includes(routeAcl.accessibleToTraineeTypes, trainee.trainee_type)) {
        hasAccess = true;
        if (routeAcl.hasEmployeeAssessmentForManager && !trainee.employee_capability_assessments) {
          hasAccess = false;
        }
      }
      if (!lodash.includes(routeAcl.accessibleToCustomerTypes, trainee.customer_type)) {
        hasAccess = false;
      }

      if (!hasAccess) {
        document.location.href = `${this.envService.errorPageUrl}`;
      }

      return hasAccess;


      // this.menuItems = lodash.filter(this.menuItems, (menuItem) => {
      //   const menuAcl = lodash.find(routesConf, (routeConf) => {
      //     return lodash.startsWith(menuItem.path, routeConf.pathPref);
      //   });

      //   let hasAccess = false;
      //   if (lodash.includes(menuAcl.accessibleToTraineeTypes, trainee.trainee_type)) {
      //     hasAccess = true;
      //     if (menuAcl.hasEmployeeAssessmentForManager && !trainee.employee_capability_assessments) {
      //       hasAccess = false;
      //     }
      //   }

      //   if (!lodash.includes(menuAcl.accessibleToCustomerTypes, trainee.customer_type)) {
      //       hasAccess = false;
      //   }

      //   return hasAccess;
      // });

    }

    // } else if (trainee.trainee_type === 'Account Admin') {
    //   // if Account Admin then make sure he

    //   const routeInfoCurr = ROUTES.find((routeInfo) => {
    //     return this.global.currentUrlNoQueryParams == routeInfo.path;
    //   });

    //   // console.log(ROUTES)
    //   // console.log(routeInfoCurr);

    //   if (routeInfoCurr.isAdminMenu) {
    //     document.location.href = `${this.envService.errorPageUrl}`;
    //     return false;
    //   } else {
    //     return true;
    //   }

    //   if (routeInfoCurr.forEmpCapAssemnt) {
    //     document.location.href = `${this.envService.errorPageUrl}`;
    //     return false;
    //   } else {
    //     return true;
    //   }

    // } else {
    //   return true;
    // }
  }

  canActivateChild() {
    const isLoggedIn = this.authService.isLoggedIn();
    if (!isLoggedIn) {
      document.location.href = this.envService.cpLoginUrl;
      return false;
    } else {
      return true;
    }
  }
}
