import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
})
export class VideoPlayerComponent implements OnInit {
  @Input() videoUrl: string;
  @Input() insideModal = false;
  @Input() embedVideo = false;
  @Output() onPlayStart = new EventEmitter<string>();

  @ViewChild('videoPlayer') videoPlayer: any;

  videoLoaded = true;
  iframeHtml = '';

  constructor(
    public activeModal: NgbActiveModal,
    public embedService: EmbedVideoService,
    public api: VgApiService,
    ) {
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;

    this.api.getDefaultMedia().subscriptions.play.subscribe(() => {
      this.onPlayStart.emit();
    });

    this.api.getDefaultMedia().subscriptions.error.subscribe(($event) => {
      this.videoLoaded = false;
    });
  }

  ngOnInit() {
    if (this.embedVideo) {
      this.iframeHtml = this.embedService.embed(this.videoUrl, {
        attr: { width: '100%', height: '600' }
      });
    }
  }

  isVideoLoaded() {
    return this.videoLoaded;
  }
}
