<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Evolution logo" [src]="logoUrl"></div>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item mr-1 mt-1" *ngIf="pwa.showInstallButton"
            placement="bottom-left" display="static">
            <a (click)="pwa.addToHome();" class="btn p-1">
              <fa-icon icon="cloud-download-alt"></fa-icon> PWA
            </a>
          </li>

          <li class="dropdown nav-item mr-3 mt-2">
            <a [href]="envService.apiUrl" class="text-info">
              <fa-icon icon="globe"></fa-icon> <span class="d-md-inline d-none"> {{'Web' | translate}}</span>
            </a>
          </li>

          <li class="i18n-dropdown dropdown nav-item mr-2 mt-2" display="dynamic" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language m-0 p-0 mt-1 mt-md-0" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="lang.selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{lang.selectedLanguageText}}</span></a>

            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/en.png" alt="flag" /><span
                  class="font-small-3">English</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="fr"
                (click)="ChangeLanguage('fr')"><img class="langimg mr-2" src="./assets/img/flags/fr.png"
                  alt="flag" /><span class="font-small-3">Français</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="sp"
                (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/sp.png"
                  alt="flag" /><span class="font-small-3">Español</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="pt"
                (click)="ChangeLanguage('pt')"><img class="langimg mr-2" src="./assets/img/flags/pt.png"
                  alt="flag" /><span class="font-small-3">Português</span></a>
            </div>
          </li>

          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
              id="dropdownBasic2" data-cy="my-profile-menu"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right align-middle" style="margin-bottom: 8px;">
                  {{trainee.first_name}} {{trainee.last_name}}
                </span>
              </div>
              <fa-stack size="lg">
                <fa-icon icon="circle" stackItemSize="2x"></fa-icon>
                <fa-icon icon="user" [inverse]="true" stackItemSize="1x"></fa-icon>
              </fa-stack>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <a class="dropdown-item" data-cy="edit-account-profile" [routerLink]="['/account/profile']">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>{{'Edit Profile' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="authService.logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{'Logout' | translate}}</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
