import { ApiConf } from './api-conf';

// export const LEARN_ROUTES: Routes
const apiConfigs = [];

apiConfigs.push(
  new ApiConf({ apiName: 'dashboard',
    urlFragments: ['/dashboard']
  }),
  new ApiConf({ apiName: 'courseContent', idName: 'course_content_id',
    queryParamsConf: {customer_course_id: 'customer_course_id'},
    urlFragments: ['/course/learn/', '/section/', '/content/']
  }),
  new ApiConf({ apiName: 'courseSection', idName: 'course_section_id',
    queryParamsConf: {customer_course_id: 'customer_course_id'},
    urlFragments: ['/course/learn/', '/section/']
  }),
  new ApiConf({ apiName: 'courseLearn', idName: 'customer_course_id',
    urlFragments: ['/course/learn/']
  }),
  new ApiConf({ apiName: 'course', idName: 'customer_course_id',
    urlFragments: ['/course/']
  }),
  new ApiConf({ apiName: 'employeeTraining', idName: 'training_id',
    queryParamsConf: {trainee_id: 'trainee_id', course_task_id: 'course_task_id'},
    urlFragments: ['/employee-training/', '/pre-answers/']
  }),
  new ApiConf({ apiName: 'employeeTraining', idName: 'training_id',
    queryParamsConf: {trainee_id: 'trainee_id', course_task_id: 'course_task_id'},
    urlFragments: ['/employee-training/', '/post-answers/']
  }),
  new ApiConf({ apiName: 'employeeTraining', idName: 'training_id',
    urlFragments: ['/employee-training/']
  }),
  new ApiConf({ apiName: 'training', idName: 'training_id',
    urlFragments: ['/training/']
  }),
  new ApiConf({ apiName: 'contact', idName: 'contact_id',
    urlFragments: ['/contact/']
  }),
  new ApiConf({ apiName: 'knowledgeBase', idName: 'knowledge_bank_id',
    urlFragments: ['/knowledge-base/']
  }),
  new ApiConf({ apiName: 'courseCommunity', idName: 'customer_course_id',
    urlFragments: ['/community/list/', '/course-community/']
  }),
  new ApiConf({ apiName: 'community', idName: 'forum_id',
    urlFragments: ['/community/']
  }),
  new ApiConf({ apiName: 'message', idName: 'message_id',
    urlFragments: ['/message/']
  }),
  new ApiConf({ apiName: 'trainingSetting', idName: 'training_id',
    urlFragments: ['/training-setting/']
  }),
  new ApiConf({ apiName: 'courseSetting', idName: 'customer_course_id',
    urlFragments: ['/course-setting/']
  }),
  new ApiConf({ apiName: 'accountProfile', idName: 'course_id',
    urlFragments: ['/account/profile/']
  }),
  new ApiConf({ apiName: 'invoice', idName: 'invoice_id',
    urlFragments: ['/account/payments/']
  }),
  new ApiConf({ apiName: 'certificate',
    urlFragments: ['/account/certificate/']
  }),
);

export const apiConfs = apiConfigs;
