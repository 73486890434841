import { ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseProgressBarService } from './course-progress-bar.service';
import { CourseProgressChangeData } from './course-progress-change-data.model';
@Component({
  selector: 'app-course-progress-bar',
  templateUrl: './course-progress-bar.component.html',
  styleUrls: ['./course-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CourseProgressBarComponent implements OnDestroy {
  @Input() completion_level: number;
  @Input() height = '30px';
  @Input() progress_bar_id = '';

  private progressChangeSubs: Subscription;

  constructor(
    private cpbs: CourseProgressBarService,
    private changeDetectRef: ChangeDetectorRef,
  ) {
    this.progressChangeSubs = this.cpbs.progressChange.subscribe(
      (data: CourseProgressChangeData) => {
        if (data.progress_bar_id) {
          if (data.progress_bar_id === this.progress_bar_id) {
            this.completion_level = data.completion_level;
          }
        } else {
          this.completion_level = data.completion_level;
        }

        this.changeDetectRef.detectChanges();
      }
    );
  }

  getProgressColor() {
    let colorType = 'danger';

    if (this.completion_level > 70) {
      colorType = 'success';  // green
    } else if (this.completion_level < 70 && this.completion_level > 30) {
      colorType = 'warning'; // Amber
    } else if (this.completion_level < 30) {
      colorType = 'danger'; // red
    }

    return colorType;
  }

  ngOnDestroy() {
    if (this.progressChangeSubs) {
       this.progressChangeSubs.unsubscribe();
     }
 }
}
