import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ComponentDataService {
  rows: any[] = [];
  id: any;
  row: any;
  vls: any; // valuelists

  // private dataSource = new BehaviorSubject('');

  constructor(
    ) {
    // currentMessage = this.dataSource.asObservable();

  }

}
