<circle-progress
  [percent]="completion_level"
  [radius]="35"
  [outerStrokeColor]="getProgressColor()"
  [animation]="false"
  [animationDuration]="300"
  [showSubtitle]="false"
  [imageSrc]="getImageSrc()"
  [showImage]="getShowImage()"
></circle-progress>
