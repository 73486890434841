import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppInjector } from '@app/app-injector';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { CourseProgressBarService } from '@app/shared/components/course-progress-bar/course-progress-bar.service';
import { CourseProgressChangeData } from '@app/shared/components/course-progress-bar/course-progress-change-data.model';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { VideoPlayerComponent } from '@app/shared/components/video-player/video-player.component';
import { DataService } from '@app/shared/services/data/data.service';
import { UiUtilService } from '@app/shared/services/ui-util.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
import swal from 'sweetalert2';
import { CourseContentMenuService } from '../shared/course.content.menu.service';
import { CourseService } from '../shared/course.service';
@Component({
  selector: 'app-course-coutent-detail',
  templateUrl: './course-content-detail.component.html',
  styleUrls: ['./course-content-detail.component.scss']
})
export class CourseContentDetailComponent extends BaseComponent implements OnInit {
  // @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  // @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild(VideoPlayerComponent) videoPlayer: VideoPlayerComponent;

  id: any;
  course: any;
  course_section_id: number;
  course_content_id: number;

  modalRef: NgbModalRef;
  sectionCurrent: any;
  contentCurrent: any;

  contentCommunityPosts: any;
  sectionCurrentInd = null;
  hasCourseCommActions = false;
  hasCurContentCommActions = false;
  curContentCommPostCompleted = false;
  curContentCommPostCommentsCompleted = false;
  isLastContentOfLastSection = false;

  translate: TranslateService;

  communityPostForm: FormGroup;
  commentCommPostForm: FormGroup;

  constructor(
    private dataService: DataService,
    actRoute: ActivatedRoute,
    private router: Router,
    private changeDetectRef: ChangeDetectorRef,
    private ccms: CourseContentMenuService,
    private cpbs: CourseProgressBarService,
    private modalService: NgbModal,
    private courseService: CourseService,

  ) {
    super(actRoute);
    this.createCommunityPostForm();
    this.createCommentCommPostForm();
  }

  refreshScreen() {
    this.changeDetectRef.detectChanges();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initData();
    let args = { course: this.course, course_section_id: this.course_section_id };
    this.ccms.menuDataChange.emit(args);

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.row = this.actRoute.snapshot.data.cpData.data;
        this.initData();
        args = { course: this.course, course_section_id: this.course_section_id };
        this.ccms.menuDataChange.emit(args);
      }
    });

    // update course progress bar
    setTimeout(() => {
      const eventData: CourseProgressChangeData = {
        completion_level: this.course.course_completion_level,
        progress_bar_id: 'overall_progress',
      };
      this.cpbs.progressChange.emit(eventData);
    }, 0);
  }

  initData() {
    this.setCourseSectionIdFromRoute();
    this.setCourseContentIdFromRoute();

    this.course = this.row;
    this.sectionCurrent = this.getCurrentSection();
    this.contentCurrent = this.getCurrentCourseContent();
    this.isLastContentOfLastSection = this.isThisLastContentOfLastSection();

    this.hasCourseCommActions = this.course.community_actions_enabled;
    this.hasCurContentCommActions = this.hasCourseCommActions && this.contentCurrent.has_community;

    this.curContentCommPostCompleted = this.hasCurContentCommActions ? this.isCurContentCommPostCompleted() : true;
    this.curContentCommPostCommentsCompleted = this.hasCurContentCommActions ? this.isCurContentCommPostCommentsCompleted() : true;

    this.setContentCommunityPosts();

    this.changeDetectRef.detectChanges();

  }

  setCourseSectionIdFromRoute() {
    this.course_section_id = lodash.toNumber(lodash.get(this.actRoute.snapshot.params, 'course_section_id', false));
  }

  setCourseContentIdFromRoute() {
    this.course_content_id = lodash.toNumber(lodash.get(this.actRoute.snapshot.params, 'course_content_id', false));
  }

  getCurrentSection() {
    const sectionCurrent = lodash.find(this.course.sections, { course_section_id: this.course_section_id });

    return sectionCurrent;
  }

  isCurContentCommPostCompleted() {
    return this.isContentCommPostCompleted(this.contentCurrent.total_posts_created, this.contentCurrent.no_of_comm_posts);
  }

  isCurContentCommPostCommentsCompleted() {
    return this.isContentCommPostCommentsCompleted(this.contentCurrent.total_comments_on_others_posts,
      this.contentCurrent.no_of_comm_comments);
  }

  isContentCommPostCompleted(total_posts_created, no_of_comm_posts) {
    if (total_posts_created >= no_of_comm_posts) {
      return true;
    } else {
      return false;
    }
  }

  isContentCommPostCommentsCompleted(total_comments_on_others_posts, no_of_comm_comments) {
    if (total_comments_on_others_posts >= no_of_comm_comments) {
      return true;
    } else {
      return false;
    }
  }

  isThisLastContentOfLastSection() {
    const course_content_id_next = this.getNextCourseContentId();
    const course_section_id_next = this.getNextCourseSectionId();
    if (!course_section_id_next && !course_content_id_next) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentCourseContent() {
    const contentCurrent = lodash.find(this.sectionCurrent.contents, { course_content_id: this.course_content_id });

    return contentCurrent;
  }

  // get next course content id after a content is completed
  getNextCourseContentId() {
    let index = lodash.findIndex(this.sectionCurrent.contents, { course_content_id: this.course_content_id });
    index++;

    let courseContent = null;
    let course_content_id = null;
    if (typeof this.sectionCurrent.contents[index] !== 'undefined') {
      courseContent = this.sectionCurrent.contents[index];
      course_content_id = courseContent['course_content_id'];
    }

    return course_content_id;
  }

  getNextCourseSectionId() {
    let index = lodash.findIndex(this.course.sections, { course_section_id: this.course_section_id });
    index++;

    let courseSection = null;
    let course_section_id = null;
    if (typeof this.course.sections[index] !== 'undefined') {
      courseSection = this.course.sections[index];
      course_section_id = courseSection['course_section_id'];
    }

    return course_section_id;
  }

  completeTheContentDialog(callback?) {
    const course_content_id_next = this.getNextCourseContentId();
    // const course_section_id_next = this.getNextCourseSectionId();

    let title = this.translate.instant('TOPIC COMPLETED');
    let text = this.translate.instant('Keep going') + '!';

    if (this.isLastContentOfLastSection) {
      // we are in the last content of the last section
      title = this.translate.instant('COURSE COMPLETED! HOORAY!!');
      text = this.translate.instant('Well done') + '!';

      const course_trainee_id = this.course.course_trainee_id;
      const toastMsg = this.translate.instant('app.courseCertificate.emailSentMessage');
      this.courseService.emailCourseCertificate(course_trainee_id, toastMsg);

    } else if (!course_content_id_next) {
      // we are in the last content of the current section
      title = this.translate.instant('LEVEL COMPLETED');
    }

    swal.fire({
      title: title,
      text: text,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    }).then(() => {
      if (callback) {
        callback.call(this);
      }
    });
  }

  completeTheContent(course_content_id: string) {
    const uiUtil = AppInjector.get(UiUtilService);

    // community actions content check posts
    if (!this.curContentCommPostCompleted && !this.isLastContentOfLastSection) {
      const remainingPostsToCreate = this.contentCurrent.no_of_comm_posts - this.contentCurrent.total_posts_created;
      let msg;
      if (remainingPostsToCreate === 1) {
        msg = 'Please create 1 post to proceed.';
      } else {
        msg = 'Please create ' + remainingPostsToCreate + ' posts to proceed.';
      }

      uiUtil.alert({
        title: 'Community Actions Pending',
        message: msg
      });

      return;
    } else if (this.isLastContentOfLastSection && this.hasCourseCommActions) { // check comments of all contents

      const contentCommentsDone = false;
      this.course.sections.forEach((section) => {
        section.contents.forEach((content) => {
          if (!contentCommentsDone) {// loop until condition is true
            // let contentCommentsDone = this.isContentCommPostCommentsCompleted(content.total_comments_on_others_posts,
            // content.no_of_comm_comments);
          }
        });
      });

      if (!contentCommentsDone) {
        const msg = `Please complete the pending community actions to complete the course.
          If comments on others post to done, please wait for others to create their post.`;
        uiUtil.alert({
          title: 'Community Actions Pending',
          message: msg
        });
        return;
      }
    }

    // save the couurse content trainee data
    const course_content_id_next = this.getNextCourseContentId();
    const course_section_id_next = this.getNextCourseSectionId();

    const callbackAfterCompleteTheContent = (returnData) => {
      this.completeTheContentDialog(() => {
        this.moveToNextContent(course_content_id_next, course_section_id_next);

        const eventData: CourseProgressChangeData = {
          completion_level: returnData.data.course_completion_level,
          progress_bar_id: 'overall_progress',
        };
        this.cpbs.progressChange.emit(eventData);
      });
    };

    const saveContent = () => {
      this.dataService.save({
        apiName: 'courseContent',
        id: course_content_id,
        queryParams: {
          customer_course_id: this.course.customer_course_id,
          course_content_id: this.course.course_content_id
        },
        showToastMsg: false,
        callbackFn: callbackAfterCompleteTheContent
      });
    };

    const callbackAfterValidation = (json) => {
      if (json.data.video_watched) {
        saveContent();
      } else {
        let msg = this.translate.instant('app.course.videoNotWatchedMessage');

        swal.fire({
          title: '',
          text: msg,
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false,
        });
      }
    }

    if (this.contentCurrent.has_video && this.videoPlayer.isVideoLoaded()) {
      this.dataService.getData('courseContent', '', {
        action: 'getVideoWatchedStatus',
        queryParams: {
          customer_course_id: this.course.customer_course_id,
          course_content_id: this.course_content_id
        }
      }).subscribe(callbackAfterValidation);
    } else {
      saveContent();
    }
  }

  moveToNextContent(course_content_id_next, course_section_id_next) {
    // if we have next course_content_id available
    if (course_content_id_next) {
      this.gotoContent(course_content_id_next);
    } else if (course_section_id_next) {
      // all contents completed. Go to next level
      this.gotoSection(course_section_id_next);
    } else {
      // all sections completed. Go to course list
      this.router.navigate(['/course/list/']);
    }
  }

  moveToNextContentFromKnowledgeReview(data) {
    const course_content_id_next = this.getNextCourseContentId();
    const course_section_id_next = this.getNextCourseSectionId();
    this.moveToNextContent(course_content_id_next, course_section_id_next);
    // const callback = () => {
    // }
    // this.completeTheContentDialog(callback);
  }

  gotoContent(course_content_id) {
    const course_section_id = this.sectionCurrent.course_section_id;

    this.router.navigate([
      '/course/learn/',
      this.course.customer_course_id,
      'section',
      course_section_id,
      'content',
      course_content_id,
    ]);
  }

  gotoSection(course_section_id) {
    this.router.navigate([
      '/course/learn/',
      this.course.customer_course_id,
      'section',
      course_section_id,
    ]);
  }

  showNewCommunityPostModal(newCommPostBodyTpl, newCommPostFooterTpl) {
    this.modalRef = this.modalService.open(ModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.title = 'New Community Post';
    this.modalRef.componentInstance.body = newCommPostBodyTpl;
    this.modalRef.componentInstance.footer = newCommPostFooterTpl;
  }

  showViewCommunityPostModal(viewCommPostBodyTpl, viewCommPostFooterTpl) {
    this.modalRef = this.modalService.open(ModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.title = 'Community Posts';
    this.modalRef.componentInstance.body = viewCommPostBodyTpl;
    // this.modalRef.componentInstance.footer = viewCommPostFooterTpl;
  }

  createCommunityPostForm() {
    this.communityPostForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
  }

  saveCommunityPostForm() {
    let formData;
    formData = this.communityPostForm.value;
    formData.course_content_id = this.contentCurrent.course_content_id;
    formData.course_id = this.course.course_id;
    formData.customer_course_id = this.course.customer_course_id;

    this.uiUtil.showSpinner();
    const trainee = JSON.parse(localStorage.getItem('trainee'));

    this.dataService.add({
      apiName: 'community',
      id: this.cds.id,
      data: formData,
      action: 'addCommunityPost',
      queryParams: {
        trainee_id_logged_in: trainee.trainee_id,
      },
      toastMsg: 'Post added to the community.',
      callbackFn: (returnData) => {
        // const rowTT = returnData.data;
        // this.trainees.push(rowTT);
        this.changeDetectRef.detectChanges();
        this.communityPostForm.reset();
        this.uiUtil.hideSpinner();
        this.modalRef.close();
        window.location.reload();
      }
    });
  }

  setContentCommunityPosts() {
    const queryParams: any = {
      course_id: this.course.course_id,
      course_content_id: this.contentCurrent.course_content_id,
    };

    this.dataService.getData('courseCommunity',
      this.course.customer_course_id,
      { queryParams: queryParams })
      .subscribe((returnData) => {
        this.contentCommunityPosts = returnData.data;
      });
  }

  createCommentCommPostForm() {
    this.commentCommPostForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
    });
  }

  addCommentCommPost(forum_id: number) {
    const formData = this.commentCommPostForm.value;

    const uiUtil = AppInjector.get(UiUtilService);
    const dataService = AppInjector.get(DataService);

    uiUtil.showSpinner();

    dataService.add({
      apiName: 'courseCommunity',
      data: formData,
      action: 'addComment',
      queryParams: {
        forum_id: forum_id,
      },
      toastMsg: 'Comment successfully Posted.',
      callbackFn: (returnData) => {
        const rowMH = returnData.data;

        this.changeDetectRef.detectChanges();

        this.commentCommPostForm.controls.description.setValue('');

        uiUtil.hideSpinner();
      }
    });
  }

  updateVideoWatchedStatus() {
    this.dataService.save({
      apiName: 'courseContent',
      action: 'updateVideoWatchedStatus',
      showToastMsg: false,
      showSpinner: false,
      data: {
        customer_course_id: this.course.customer_course_id,
        course_content_id: this.course_content_id,
        video_started: 1
      },
    });
  }
}
