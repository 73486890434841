import * as lodash from 'lodash';

// Trainee
// Account Admin
// Manager

// isAdminMenu
// forEmpCapAssemnt
// forCompanyCustomer (Individual / Company)

// /employee-training/detail/4

// Trainee (only trainee related urls)
// Manager (Trainee + employee training urls)
// Account Admin (Trainee + company settings urls)

// IF Customer Type = Company
// then show community urls

export class RouteAcl {
  pathPref = ''; // path prefix

  // access to the user types: avaiable types are Trainee / Account Admin / Manager
  accessibleToTraineeTypes = ['Trainee', 'Account Admin', 'Manager'];
  accessibleToCustomerTypes = ['Individual', 'Company'];
  // this url path is accessible only to trainee type "Manager" who has "Behavioural Analysis Test"
  hasEmployeeAssessmentForManager = false;

  constructor(obj: any) {
    this.pathPref = lodash.has(obj, 'pathPref') ? obj.pathPref : '';
    this.accessibleToTraineeTypes = lodash.has(obj, 'accessibleToTraineeTypes') ?
      obj.accessibleToTraineeTypes : this.accessibleToTraineeTypes;
    this.accessibleToCustomerTypes = lodash.has(obj, 'accessibleToCustomerTypes') ?
      obj.accessibleToCustomerTypes : this.accessibleToCustomerTypes;

    this.hasEmployeeAssessmentForManager = lodash.has(obj, 'hasEmployeeAssessmentForManager') ?
      obj.hasEmployeeAssessmentForManager : this.hasEmployeeAssessmentForManager;
  }
}
