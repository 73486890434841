import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UiUtilService } from '@app/shared/services/ui-util.service';
import { Observable } from 'rxjs';
import { ApiConf } from './api-conf';
import { APIDetailResolver } from './api-details';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class DataResolver implements Resolve<Observable<string>> {
  constructor(
    private dataService: DataService,
    private apiDetailResolver: APIDetailResolver,
    private uiUtil: UiUtilService,
  ) { }

  resolve(routeSnapshot: ActivatedRouteSnapshot): Observable<string> {
    const url = this.uiUtil.getResolvedUrl(routeSnapshot);
    const routeParams = this.uiUtil.getResolvedParams(routeSnapshot);

    const apiConf: ApiConf = this.apiDetailResolver.getApiDetails(url, routeParams);
    const apiName = apiConf.apiName;
    const id = apiConf.id;

    return this.dataService.getData(apiName, id, { apiConf: apiConf });
  }
}
