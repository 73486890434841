import {
  AfterViewInit, ChangeDetectorRef, Component,
  ElementRef, HostListener, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { customAnimations } from '../animations/custom-animations';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { ROUTES } from './vertical-menu-routes.config';
import { RouteInfo } from './vertical-menu.metadata';
import { routesAclConf } from '@shared/services/data/routes-acl-setup';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  getTrainee(): any {
    return JSON.parse(localStorage.getItem('trainee'));
  }

  ngOnInit() {
    this.menuItems = ROUTES;
    const trainee = this.getTrainee();
    // console.log(trainee)

    this.menuItems = lodash.filter(this.menuItems, (menuItem) => {
      const menuAcl = lodash.find(routesAclConf, (routeConf) => {
        return lodash.startsWith(menuItem.path, routeConf.pathPref);
      });

      let hasAccess = false;
      if (lodash.includes(menuAcl.accessibleToTraineeTypes, trainee.trainee_type)) {
        hasAccess = true;
        if (menuAcl.hasEmployeeAssessmentForManager && !trainee.employee_capability_assessments) {
          hasAccess = false;
        }
      }

      if (!lodash.includes(menuAcl.accessibleToCustomerTypes, trainee.customer_type)) {
          hasAccess = false;
      }

      return hasAccess;
    });
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  isLinkActive(menuItem: RouteInfo) {
    const url = this.router.url;

    // console.log(menuItem)
    if (lodash.startsWith(url, menuItem.path)) {
      return true;
    }

    if (menuItem.rootPath && lodash.startsWith(url, menuItem.rootPath)) {
      return true;
    }

    return false;
  }

  loadLayout() {
    return;
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
}
