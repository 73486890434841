<div class="course-content-menu">
  <div class="row">
    <div class="col-6">
      <a [routerLink]="['/course/detail/', customer_course_id]">
        <fa-icon icon="home" size="1x" class="text-white"></fa-icon>
      </a>
      </div>
    <div class="col-6 text-right">
      <fa-icon icon="times" class="py-2 cursor-pointer text-white" (click)="_closeMenu()"></fa-icon>
    </div>
  </div>

  <div class="h4 px-2 title pt-4 pb-2 white">{{'Course Content' | translate}}</div>
  <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [activeIds]="['panel-' + course_section_id_current]">
    <ngb-panel *ngFor="let section of courseSections; index as i"
      [cardClass]="'section'"
      id="panel-{{section.course_section_id}}">

      <ng-template ngbPanelTitle>
        <div class="row" [routerLinkActive]="['active']">

          <div class="col-2">
            {{section.time_frame}}
            <div class="mt-1">
              <ng-container *ngIf="section.completed else elseifBlockCSIcon">
                <fa-icon icon="thumbs-up" class="fa-1x"></fa-icon>
              </ng-container>
              <ng-template #elseifBlockCSIcon>
                <ng-container *ngIf="!section.locked else elseBlockCSIcon">
                  <fa-icon icon="lock-open" class="fa-1x"></fa-icon>
                </ng-container>
              </ng-template>
              <ng-template #elseBlockCSIcon>
                <fa-icon icon="lock" class="fa-1x locked-icon"></fa-icon>
              </ng-template>
            </div>
          </div>

          <div class="col-10 p-0">
            <div class="font-weight-bold">{{'Level__smalls' | translate}}: {{section.sort_order}}</div>
            <div>
              <ng-container *ngIf="section.locked else elseBlockSec">
                {{section.title}}
              </ng-container>
              <ng-template #elseBlockSec>
                <a
                  [routerLink]="['/course/learn/', course.customer_course_id, 'section', section.course_section_id]">
                  {{section.title}}
                </a>
              </ng-template>
            </div>
          </div>

        </div>
      </ng-template>

      <ng-template ngbPanelContent>
        <div class="py-2" *ngFor="let content of section.contents">
          <div class="row content" [routerLinkActive]="['active']">

            <div class="col-2 text-right align-middle">
              <div class="mr-3">
                <ng-container *ngIf="content.completed else elseifBlockCCM">
                  <fa-icon icon="thumbs-up" class="fa-1x"></fa-icon>
                </ng-container>
                <ng-template #elseifBlockCCM>
                  <ng-container *ngIf="!content.locked else elseBlockCCM">
                    <fa-icon icon="lock-open" class="fa-1x"></fa-icon>
                  </ng-container>
                </ng-template>
                <ng-template #elseBlockCCM>
                  <fa-icon icon="lock" class="fa-1x locked-icon"></fa-icon>
                </ng-template>
              </div>
            </div>

            <div class="col-10">
              <span class="h6">
                <ng-container *ngIf="content.locked else elseBlock">
                  <span class="text-secondary">
                    {{content.sort_order}}: {{content.title}}
                  </span>
                </ng-container>
                <ng-template #elseBlock>
                  <a [routerLink]="['/course/learn/', course.customer_course_id, 'section',
                    section.course_section_id, 'content', content.course_content_id]">
                    {{content.sort_order}}: {{content.title}}
                  </a>
                </ng-template>

              </span><br>
              <span class="text-italic font-small-3">
                {{content.time_frame}}
              </span>
            </div>
          </div>
        </div>
      </ng-template>

    </ngb-panel>
  </ngb-accordion>
</div>
