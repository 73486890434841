import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { TranslateLoader } from '@ngx-translate/core';
import { Observer } from 'rxjs';
import { Observable } from 'rxjs';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class CpTranslateLoader implements TranslateLoader {
  // contentHeader = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

  constructor(private http: HttpClient, public util: UtilService) { }

  getTranslation(lang: string): Observable<any> {
    const util = AppInjector.get(UtilService);

    // const lang3Letter = util.getLangPrefix3Letter(lang);
    const apiAddress = '/cpapi/translation/';

    return new Observable((observer: Observer<any>) => {
      // this timeout delay is for the login component to set the id
      setTimeout(() => {
        this.http.get(apiAddress).subscribe((response: any) => {
          observer.next(response.data);
          observer.complete();
        });
      }, 700);
    });
  }
}
