import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faBookReader,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faComment,
  faDotCircle,
  faFileExcel,
  faDownload,
  faEdit,
  faFileAlt,
  faFilePdf,
  faImage,
  faLayerGroup,
  faLightbulb,
  faLock,
  faLockOpen,
  faPencilAlt,
  faPrint,
  faQuestionCircle,
  faRedo,
  faSignal,
  faSpinner,
  faThumbsUp,
  faTimes,
  faVideo,
  faPlusCircle,
  faEnvelope,
  faPaperPlane,
  faNewspaper,
  faCheckSquare,
  faCheckCircle,
  faUser,
  faUsers,
  faEyeSlash,
  faEye,
  faPaperclip,
  faArrowRight,
  faAward,
  faShoppingCart,
  faHome,
  faBookOpen,
  faBook,
  faBars,
  faSortAmountUp,
  faCloudDownloadAlt,
  faGlobe,
  faUserCheck,
  faInfoCircle,
  faCommentAlt,
  faTv,
  faDollarSign,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';

import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    // add icons to the library for convenient access in other components
    library.addIcons(
      faBookReader,
      faCheck,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faCircle,
      faClock,
      faCog,
      faComment,
      faDotCircle,
      faFileExcel,
      faDownload,
      faEdit,
      faFileAlt,
      faFilePdf,
      faImage,
      faLayerGroup,
      faLightbulb,
      faLock,
      faLockOpen,
      faPencilAlt,
      faPrint,
      faQuestionCircle,
      faRedo,
      faSignal,
      faSpinner,
      faThumbsUp,
      faTimes,
      faVideo,
      faPlusCircle,
      faEnvelope,
      faPaperPlane,
      faNewspaper,
      faCheckSquare,
      faCheckCircle,
      faUser,
      faUsers,
      faUserCheck,
      farCircle,
      faEye,
      faPaperclip,
      faEyeSlash,
      faArrowRight,
      faAward,
      faShoppingCart,
      faHome,
      faBookOpen,
      faBook,
      faBars,
      faSortAmountUp,
      faCloudDownloadAlt,
      faGlobe,
      faInfoCircle,
      faCommentAlt,
      faLayerGroup,
      faTv,
      faLightbulb,
      faDollarSign,
      faEllipsisV,
    );

  }
}
