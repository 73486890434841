import { Component, OnInit } from '@angular/core';
import { AppInjector } from '@app/app-injector';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { UiUtilService } from '@app/shared/services/ui-util.service';
import { CourseService } from '../shared/course.service';

@Component({
  selector: 'app-course-learn',
  templateUrl: './course-learn.component.html',
  styleUrls: ['./course-learn.component.scss']
})
export class CourseLearnComponent extends BaseComponent implements OnInit {
  id: any;
  course: any;

  public courseService: CourseService;
  public uiUtil: UiUtilService;

  sectionCurrent: any;
  sectionCurrentInd = null;

  ngOnInit() {
    this.courseService = AppInjector.get(CourseService);
    this.uiUtil = AppInjector.get(UiUtilService);

    super.ngOnInit();

    this.course = this.row;
  }

  getSectionImage(section) {
    let img = '';
    if (section.has_picture) {
      img = section?.picture?.file_normal;
    }

    if (img) {
      img = "url('" + img + "')";
    }

    return img;
  }
}
