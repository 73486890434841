import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as lodash from 'lodash';
import { EnvService } from '../services/env.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private envService: EnvService, private util: UtilService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    let path = req.url.split('/');
    path = lodash.without(path, '', '.');

    const apiPrefix = path[0];

    // normal URLs other than cpapi URLs are not further processed and returned to run normall
    // normal URLs: router paths, images, PDF files etc
    if (apiPrefix !== 'cpapi') {
      return next.handle(req);
    }

    // for the cpapi URLs we appendthe logged in ID token to the request Authorization headers.
    const idToken = localStorage.getItem('id_token');
    const language = localStorage.getItem('language');

    const lang3Letter = this.util.getLangPrefix3Letter(language);
    let url = req.url;
    let paramPrefix = '?';

    if (url.indexOf('?') !== -1) {
      paramPrefix = '&';
    }

    url = url + paramPrefix + 'lang=' + lang3Letter;
    url = this.envService.apiUrl + url;

    const clonedReq = req.clone({
      url: url
    });

    if (idToken) {
      // setting the authorization header in smaller case below because Cypress test suite
      // automatically convert all the headers to smaller case. So we just made the header smaller case
      const clonedNewReq = clonedReq.clone({
        headers: req.headers.set('authorization',
          'Bearer ' + idToken)
      });
      // console.log(clonedNewReq);

      //send the newly created request
      return next.handle(clonedNewReq).pipe(
        map((response: HttpResponse<any>) => {
          // console.log(response)
          // console.log(response.json())
          return response;
          // return this.processData(response, modelType);
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(clonedReq);
    }
  }
}
