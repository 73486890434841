<ng-template let-course="course" #courseOverViewTpl>
  <div class="card p-3 mt-0">
    <div class="card-content">
      <div class="row">
        <div class="col-12 col-md-5 order-md-12">
          <img class="img-fluid img-course rounded cursor-pointer"
            [src]="util.getImageSrc(row.picture.file_large)"
            (click)="uiUtil.openLightbox(course.picture.file_large)">
        </div>
        <div class="col-12 col-md-7 mt-2 mt-md-0 order-md-1">
          <p [innerHTML]="course.description" class='card-text'></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-course="course" #courseLevelsTpl>
  <div class="col-12 col-md-6 col-xl-4 mt-2 section" data-cy-section
    *ngFor="let section of course.sections; index as i">
    <div class="card overflow-hidden m-1">
      <div class="card-header text-center pb-2">
        <div data-cy="goto-section" class="row section-image-row" [ngClass]="!section.locked ? 'cursor-pointer' : ''"
          [routerLink]="!section.locked ?
              ['/course/learn/', course.customer_course_id, 'section', section.course_section_id] : []">
          <div class="col-4 level" [style.background-color]="courseService.getCourseColor(section)">
            <div class="mt-4 font-weight-bold">{{'Level_' | translate}}</div>
            <h1 class="text-white">{{section.sort_order|number:'2.0'}}</h1>
          </div>

          <div *ngIf="!section.locked; then thenBlockSectionWithoutFadeImg else elseBlockSectionImgFade"></div>
          <ng-template #thenBlockSectionWithoutFadeImg>
            <div class="col image" [ngStyle]="{'background-image': getSectionImage(section)}"></div>
          </ng-template>
          <ng-template #elseBlockSectionImgFade>
            <div class="col image opacity-3" [ngStyle]="{'background-image': getSectionImage(section), opacity: 0.6}"></div>
          </ng-template>
        </div>

        <div class="row mt-3">
          <div class="col-8 h5 text-left">
            <ng-container *ngIf="!section.locked else elseBlockSectionTitle">
              <a [routerLink]="['/course/learn/', course.customer_course_id, 'section', section.course_section_id]">
                {{section.title}}
              </a>
            </ng-container>
            <ng-template #elseBlockSectionTitle>
              {{section.title}}
            </ng-template>
          </div>

          <div class="col-4">
            <app-course-progress-bar-round
            [completion_level]="section.section_completion_level"
            [locked]="section.locked"
            ></app-course-progress-bar-round>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<!----------- Template ----------->
<div class="mb-4">
  <ng-container *ngTemplateOutlet="courseOverViewTpl; context:{course: course}">
  </ng-container>
  <div class="mt-4">
    <div class="p-1">
      <span class="h5">
        <fa-icon icon="sort-amount-up" class="text-muted"></fa-icon>
        {{'LEVELS' | translate}} ({{ course.sections.length }})</span>
      </div>

      <div class="row">
        <ng-container *ngTemplateOutlet="courseLevelsTpl; context:{course: course}">
        </ng-container>
      </div>
    </div>
  </div>
