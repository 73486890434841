import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { EnvService } from '../services/env.service';

@Injectable()
export class AuthService {

  constructor(
    public router: Router,
    private http: HttpClient,
    private envService: EnvService,
  ) {
    // console.log("auth service")
    // this line is easy e2e testing
    // if (window['Cypress']) {
    //   window['AuthService'] = this;
    // }
  }

  signinUser(email: string, password: string, idToken: string = '') {
    let data = null;
    data = { 'email': email, 'password': password };
    if (idToken) {
      data = { idToken: idToken };
    }
    return this.http.post<any>('/cpapi/login', data)
      .pipe(map(result => {
        if (result.status === 'success') {
          this.setSessionData(result);
        }
        return result;
      }));
  }

  public setSessionData(authResult) {
    const expiresAt = moment().add(authResult.data.expiresIn, 'second');

    localStorage.setItem('id_token', authResult.data.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    this.setTrainee(authResult.data.trainee);
  }

  public clearSessionData() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('trainee');
  }

  public setTrainee(trainee) {
    localStorage.setItem('trainee', JSON.stringify(trainee));
  }

  public getTrainee() {
    const trainee = JSON.parse(localStorage.getItem('trainee'));

    return trainee;
  }

  // logged in means we need to have an id_token which is not expired
  public isLoggedIn() {
    const id_token = localStorage.getItem('id_token');

    let isLoggedIn = id_token && this.tokenExpired();

    return isLoggedIn;
  }

  public tokenExpired() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  logout() {
    this.clearSessionData();
    const url = this.envService.cpLogoutUrl;

    document.location.href = url;
  }
}
