<ng-template #videoTpl>
  <ng-container *ngIf="embedVideo else elseBlockTplVideo">
    <div [innerHtml]="iframeHtml"></div>
  </ng-container>
  <ng-template #elseBlockTplVideo>
    <vg-player (onPlayerReady)="onPlayerReady($event)">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>

      <vg-controls [vgAutohide]="true" [vgAutohideTime]="2">
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

        <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

        <!-- <vg-track-selector></vg-track-selector> -->
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>

      <video [vgMedia]="media" #media id="singleVideo" preload="auto"
        [src]="videoUrl" type="video/mp4" crossorigin controlsList="nodownload">
      </video>
    </vg-player>
  </ng-template>
</ng-template>

<ng-container *ngIf="insideModal else elseIfBlockTpl">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="videoTpl">
    </ng-container>
  </div>
</ng-container>

<ng-template #elseIfBlockTpl>
  <ng-container *ngTemplateOutlet="videoTpl" #videoPlayer>
  </ng-container>
</ng-template>

<!--
<ng-container *ngTemplateOutlet="videoTpl" #videoPlayer>
</ng-container> -->
