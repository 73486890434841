import * as lodash from 'lodash';

export class RouteInfo {
  path = '';
  rootPath = '';
  title = '';
  icon = '';
  class = '';
  isAdminMenu = false;
  forCompanyCustomer = false;
  forEmpCapAssemnt = false;
  name = '';
  badge = '';
  badgeClass = '';
  isExternalLink = false;
  submenu: RouteInfo[] = [];

  constructor(obj: any) {
    this.path           = lodash.has(obj, 'path') ? obj.path                     : '';
    this.rootPath       = lodash.has(obj, 'rootPath') ? obj.rootPath             : '';
    this.title          = lodash.has(obj, 'title') ? obj.title                   : '';
    this.icon           = lodash.has(obj, 'icon') ? obj.icon                     : '';
    this.class          = lodash.has(obj, 'class') ? obj.class                   : '';
    this.isAdminMenu    = lodash.has(obj, 'isAdminMenu') ? obj.isAdminMenu       : this.isAdminMenu;
    this.name           = lodash.has(obj, 'name') ? obj.name                     : '';
    this.badge          = lodash.has(obj, 'badge') ? obj.badge                   : '';
    this.badgeClass     = lodash.has(obj, 'badgeClass') ? obj.badgeClass         : '';
    this.isExternalLink = lodash.has(obj, 'isExternalLink') ? obj.isExternalLink : this.isExternalLink;
    this.submenu        = lodash.has(obj, 'submenu') ? obj.submenu               : this.submenu;

    // /account/profile will be changed to account-profile
    const path = this.path.substring(1);
    this.name = this.name ? this.name : path.split('/').join('-');

    this.forCompanyCustomer = lodash.has(obj, 'forCompanyCustomer') ?
      obj.forCompanyCustomer : this.forCompanyCustomer;

    this.forEmpCapAssemnt = lodash.has(obj, 'forEmpCapAssemnt') ?
      obj.forEmpCapAssemnt : this.forEmpCapAssemnt;
  }
}
