<ng-template let-course="course" let-content="content" #videoTpl>
  <app-video-player #videoPlayer [videoUrl]="content.video.file_normal" (onPlayStart)="updateVideoWatchedStatus()">
  </app-video-player>
</ng-template>

<ng-template let-course="course" #contentDetailTpl>
  <div class="header-panel2 rounded h5">
    <div class="row">
      <div class="col-12 col-md-7">
        <div class="text-danger">
          {{'LEVEL' | translate}} {{sectionCurrent.sort_order}}
        </div>
        <div class="text-success title mt-2">
          {{'TOPIC' | translate}} {{contentCurrent.sort_order}} <span class="ml-1">{{contentCurrent.title}}</span>
        </div>
      </div>

      <div class="col-12 col-md-5">
        <div class="row mt-3 mt-md-0">
          <div class="col-6 h6 text-right">
            <div *ngIf="contentCurrent.time_frame">{{'Time Frame' | translate}}:</div>
          </div>
          <div class="col-6 h6">
            <div *ngIf="contentCurrent.time_frame">
              {{contentCurrent.time_frame}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 h6 text-right">
            <div *ngIf="contentCurrent.difficulty_level">{{'Difficulty Level' | translate}}:</div>
          </div>
          <div class="col-6 h6">
            <div *ngIf="contentCurrent.difficulty_level">
              {{contentCurrent.difficulty_level | translate}}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ng-container *ngIf="contentCurrent.has_video else elseBlockCCD1" class="video-player mt-3">
    <div>
      <ng-container *ngTemplateOutlet="videoTpl; context:{course: course, content: contentCurrent}">
      </ng-container>
    </div>
  </ng-container>
  <ng-template #elseBlockCCD1>
    <ng-container *ngIf="contentCurrent.has_picture;">
      <ng-container *ngIf="contentCurrent.picture" class="picture mt-3">
        <div class="card p-2">
          <img class="img-rounded" src="{{contentCurrent?.picture?.file_large}}" class="card-img" style="width: 50%;">
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <div class="card p-2">
    <div class="card-body p-0">
      <ul ngbNav #nav="ngbNav" class="nav-pills mt-1">
        <li [ngbNavItem]="1">
          <a ngbNavLink>{{'Overview' | translate}}</a>
          <ng-template ngbNavContent>

            <div class="text-section">
              <div class="mb-3" *ngIf="contentCurrent.caption">
                <div class="header d-flex flex-row">
                  <div>{{'Outline' | translate}}</div>
                  <div class="ml-auto">
                    <fa-icon icon="tv" class="mr-1"></fa-icon>
                  </div>
                </div>
                <p [innerHTML]="contentCurrent.caption" class="p-2"></p>
              </div>

              <div class="mb-3" *ngIf="contentCurrent.description">
                <div class="header d-flex flex-row">
                  <div>{{'Objectives' | translate}}</div>
                  <div class="ml-auto">
                    <fa-icon icon="bars" class="mr-1"></fa-icon>
                  </div>
                </div>
                <p [innerHTML]="contentCurrent.description" class="p-2"></p>
              </div>

              <div class="mb-3" *ngIf="contentCurrent.tips">
                <div class="header d-flex flex-row">
                  <div>{{'Top Tips' | translate}}</div>
                  <div class="ml-auto">
                    <fa-icon icon="lightbulb" class="mr-1"></fa-icon>
                  </div>
                </div>
                <p [innerHTML]="contentCurrent.tips" class="p-2"></p>
              </div>
            </div>

          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>{{'Resources' | translate}}</a>
          <ng-template ngbNavContent>
            <div class="m-2" *ngFor="let att of contentCurrent.attachment">
              <a href="{{att.file_normal}}" target="_blank" download="newfilename">
                <fa-icon icon="paperclip" class="mr-2"></fa-icon>{{att.actual_file_name}}
              </a>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>

  <ng-container *ngIf="hasCurContentCommActions">
    <div class="card">
      <div class="card-header">
        <h5 class="">{{'Content Community Actions' | translate}}</h5>
      </div>
      <div class="card-body pt-0">
        <p class="card-text">
          <ng-container *ngIf="contentCurrent.no_of_comm_posts > 0 || contentCurrent.no_of_comm_comments > 0">
            <strong class='text-warning'> {{'app.courseContent.detailText' | translate}}
            </strong> <br>
          </ng-container>
          <ng-container *ngIf="contentCurrent.no_of_comm_posts > 0">
            <strong>{{'Make own posts' | translate}}:</strong> {{contentCurrent.total_posts_created}} /
            {{contentCurrent.no_of_comm_posts}}
            <span class="badge badge-success mb-1 mr-2" *ngIf="curContentCommPostCompleted">{{'Completed' | translate}}</span>
            <span class="badge badge-warning mb-1 mr-2" *ngIf="!curContentCommPostCompleted">Pending</span>
            <br />
          </ng-container>

          <ng-container *ngIf="contentCurrent.no_of_comm_comments > 0">
            <strong>{{'Comments on others posts' | translate}}:</strong> {{contentCurrent.total_comments_on_others_posts}} /
            {{contentCurrent.no_of_comm_comments}}
            <span class="badge badge-success mb-1 mr-2" *ngIf="curContentCommPostCommentsCompleted">{{'Completed' | translate}}</span>
            <span class="badge badge-warning mb-1 mr-2" *ngIf="!curContentCommPostCommentsCompleted">{{'Pending' | translate}}</span>
            <br /><br />
          </ng-container>

          <a (click)="showNewCommunityPostModal(newCommPostBodyTpl, newCommPostFooterTpl)" class="btn btn-info">
            <fa-icon icon="plus-circle"></fa-icon> {{'Create New Post' | translate}}
          </a>&nbsp;

          <a (click)="showViewCommunityPostModal(viewCommPostBodyTpl, viewCommPostFooterTpl)" class="btn btn-success">
            <fa-icon icon="eye"></fa-icon> {{'View Posts' | translate}}
          </a>
        </p>
      </div>
    </div>
  </ng-container>

  <!----------- Community Actions Last Section Last Content ----------->
  <ng-container *ngIf="hasCourseCommActions && isLastContentOfLastSection">
    <div class="card">
      <div class="card-header">
        <h5 class="">{{'Course Community Actions' | translate}}</h5>
      </div>
      <div class="card-body pt-0">
        <p class="card-text">
          <ng-container *ngIf="contentCurrent.no_of_comm_posts > 0 || contentCurrent.no_of_comm_comments > 0">
            <strong class='text-warning'> {{'app.courseContent.detailText1' | translate}}
            </strong> <br>
          </ng-container>

          <ng-container *ngFor="let section of course.sections; index as i">
            <ng-container *ngFor="let content of section.contents; index as j">
              <ng-container *ngIf="content.has_community">
                <h6 class='mt-2'>{{'LEVEL' | translate}} {{section.sort_order}} / {{'TOPIC' | translate}} {{content.sort_order}}
                  <span class="ml-2 title">{{content.title}}</span>
                </h6>

                <ng-container *ngIf="content.no_of_comm_posts > 0">
                  <strong>{{'Make own posts' | translate}}:</strong> {{content.total_posts_created}} / {{content.no_of_comm_posts}}
                  <span class="badge badge-success mb-1 mr-2"
                    *ngIf="isContentCommPostCompleted(content.total_posts_created, content.no_of_comm_posts)">{{'LEVEL' | translate}}Completed</span>
                  <span class="badge badge-warning mb-1 mr-2"
                    *ngIf="!isContentCommPostCompleted(content.total_posts_created, content.no_of_comm_posts)">{{'LEVEL' | translate}}Pending</span>
                  <br />
                </ng-container>

                <ng-container *ngIf="content.no_of_comm_comments > 0">
                  <strong>{{'Comments on others posts' | translate}}:</strong> {{content.total_comments_on_others_posts}} /
                  {{content.no_of_comm_comments}}
                  <span class="badge badge-success mb-1 mr-2"
                    *ngIf="isContentCommPostCommentsCompleted(content.total_comments_on_others_posts, content.no_of_comm_comments)">{{'Completed' | translate}}</span>
                  <span class="badge badge-warning mb-1 mr-2"
                    *ngIf="!isContentCommPostCommentsCompleted(content.total_comments_on_others_posts, content.no_of_comm_comments)">{{'Pending' | translate}}</span>
                  <br /><br />
                </ng-container>

              </ng-container>
            </ng-container>
          </ng-container>
        </p>
      </div>
    </div>
  </ng-container>

  <div class="text-center my-4">
    <ng-container *ngIf="contentCurrent.completed; else elseBlockCCD2">
      <span class="alert alert-success py-2 px-4 h4">
        {{'TOPIC COMPLETED' | translate}}!!
        <fa-icon icon="thumbs-up"></fa-icon>
      </span>
    </ng-container>
    <ng-template #elseBlockCCD2>
      <button type="button" class="btn btn-success" data-cy-complete-the-content-btn
        (click)="completeTheContent(contentCurrent.course_content_id)">
        <fa-icon icon="thumbs-up"></fa-icon> {{'I HAVE COMPLETED THIS TOPIC' | translate}}
      </button>
    </ng-template>
  </div>
</ng-template>

<!----------- Template ----------->
<ng-container *ngIf="contentCurrent.content_type == 'Content' else elseifBlock">
  <ng-container *ngTemplateOutlet="contentDetailTpl; context:{course: course}">
  </ng-container>
</ng-container>
<ng-template #elseifBlock>
  <ng-container *ngIf="contentCurrent.content_type == 'Quiz'">
    <ng-container *ngIf="quiz.quizStatus == '' ">

      <div class="card p-3">
        <p [innerHTML]="contentCurrent.description"></p>
        <p [innerHTML]="quiz.quizData.description"></p>
        <div class="font-weight-bold mt-3">
          {{'app.courseContent.detailText3' | translate}}
        </div>
        <p class="mt-2" [innerHTML]="contentCurrent.caption"></p>
        <ng-container *ngIf="quiz.quizData.pass_percentage">
          <p class="mt-2">
            <strong>{{'Pass' | translate}} %:</strong> {{quiz.quizData.pass_percentage}}
          </p>
        </ng-container>
      </div>

      <div class="my-3 text-right">
        <a class="btn btn-success" (click)="quiz.startQuiz()">
          {{'Start Quiz' | translate}} <fa-icon icon="chevron-right"></fa-icon>
        </a>
      </div>
      <div class="mt-3 text-right">

      </div>
    </ng-container>

    <app-knowledge-review #quiz [courseSection]="sectionCurrent" [courseContent]="contentCurrent"
      [quizId]="contentCurrent.quiz_id"
      [customerCourseId]="course.customer_course_id"
      [contentOrTaskCompleted]="contentCurrent.completed"
      [quizStatus]="contentCurrent.completed || contentCurrent.quiz_attempted ? 'ended' : '' "
      [isLastContentOfLastSection]="isLastContentOfLastSection"
      (quizCompleted)="moveToNextContentFromKnowledgeReview($event)"
      (dataLoaded)="refreshScreen($event)">
    </app-knowledge-review>
  </ng-container>
</ng-template>

<!------ New Community Post -------->
<ng-template #newCommPostBodyTpl let-modal=contentCurrent1>
  <div class="container">
    <h5>{{course.title}}</h5>
    <h6>
      {{'LEVEL' | translate}} {{sectionCurrent.sort_order}} / {{'TOPIC' | translate}} {{contentCurrent.sort_order}}
      <span class="ml-2 title">{{contentCurrent.title}}</span>
    </h6>
    <form [formGroup]="communityPostForm">
      <div class="form-group">
        <label for="description">{{'Message' | translate}}</label>
        <textarea class="form-control" formControlName="description" cols="30" rows="10"></textarea>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #newCommPostFooterTpl>
  <button type="button" class="btn btn-outline-dark" (click)="saveCommunityPostForm()">{{'Submit' | translate}}</button>
</ng-template>

<!------ View Community Post -------->
<ng-template #viewCommPostBodyTpl let-modal=contentCommunityPosts>
  <div class="container">
    <ng-container *ngIf="contentCommunityPosts.length > 0; else noItems">
      <div class="card" *ngFor="let row of contentCommunityPosts; index as i">
        <div class="card-content">
          <div class="card-body">
            <div class="mb-2">
              <h5>{{row.course_title}}</h5>
              <h6>
                {{'LEVEL' | translate}} {{row.section_sort_order}} / {{'TOPIC' | translate}} {{row.content_sort_order}}
                <span class="ml-2 title">{{row.course_content_title}}</span>
              </h6>
              <div class="row">
                <div class="col">
                  <ul class="list-unstyled users-list m-0 d-flex align-items-center">
                    <li data-toggle="tooltip" data-popup="tooltip-custom" placement="bottom" container="body"
                      class="avatar pull-up ng-star-inserted">
                      <img alt="Avatar" height="30" width="30" src="assets/img/portrait/small/avatar-s-placeholder.png">
                    </li>
                    <li class="d-inline-block pl-2">
                      <p class="text-muted mb-0 font-small-3">{{row.trainee_name}}</p>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <p class="text-muted font-small-3 text-right">{{'Posted on' | translate}} {{row.creation_date | date:'longDate'}} at
                    {{row.creation_date | date:'shortTime'}}</p>
                </div>
              </div>

            </div>
            <p [innerHTML]="row.description"></p>

            <h5>{{'Comments' | translate}}</h5>
            <div class="container mb-3" *ngFor="let rowH of row.forumHistory; index as j">
              <div class="row">
                <div class="col col-md-2">
                  <img *ngIf="rowH.posted_by == 'Trainer'" src="{{rowH.picture.file_normal}}" alt="user edit avatar"
                    height="64" width="64" class="users-avatar-shadow avatar mr-3 rounded-circle">
                  <img *ngIf="rowH.posted_by == 'Trainee'"
                    src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png" alt="user edit avatar"
                    height="64" width="64" class="users-avatar-shadow avatar mr-3 rounded-circle">
                </div>
                <div class="col border col-md-10">
                  <div class="row mt-1 bold">
                    <div *ngIf="rowH.posted_by == 'Trainer'" class="font-weight-bold col col-md-6">
                      {{rowH.trainer_name}}
                    </div>
                    <div *ngIf="rowH.posted_by == 'Trainee'" class="font-weight-bold col col-md-6">
                      {{rowH.trainee_name}}
                    </div>
                    <div class="font-weight-bold col col-md-6 text-right">
                      {{rowH.creation_date | date:'longDate'}} at {{rowH.creation_date | date:'shortTime'}}
                    </div>
                    <div class="col mt-1 col-md-12">
                      {{rowH.description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container mb-3">
              <div class="container my-3">
                <div class="row">
                  <div class="col text-right">
                    <form [formGroup]="commentCommPostForm">
                      <div class="form-group form-group-lg">
                        <textarea class="form-control form-rounded heighttext" formControlName="description" required
                          placeholder="Type your comment..."></textarea>
                      </div>
                    </form>
                    <button type="button" class="btn btn-primary" [disabled]="!commentCommPostForm.valid"
                      (click)="addCommentCommPost(row.forum_id)">
                      {{'Submit' | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noItems>
      {{'No Posts' | translate}}.
    </ng-template>
    <!-- <app-community-list></app-community-list> -->
  </div>
</ng-template>

<ng-template #viewCommPostFooterTpl>
</ng-template>
