import { GlobalService } from '@shared/services/global.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ChangeDetectorRef, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MESSAGES_PIPE_FACTORY_TOKEN, MESSAGES_PROVIDER, NgxValidationErrorsModule } from '@xtream/ngx-validation-errors';
import { AuthInterceptor } from 'app/shared/auth/auth.interceptor';
import {
  PerfectScrollbarConfigInterface, PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { setAppInjector } from '@app/app-injector';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ContentLayoutComponent } from '@app/layouts/content/content-layout.component';
import { FullLayoutComponent } from '@app/layouts/full/full-layout.component';
import { AuthGuard } from '@app/shared/auth/auth-guard.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { DataResolver } from '@app/shared/services/data/data.resolver';
import { EnvService } from '@app/shared/services/env.service';
import { CpTranslateLoader } from '@app/shared/services/translation-loader.service';
import { WINDOW_PROVIDERS } from '@app/shared/services/window.service';
import { SharedModule } from '@app/shared/shared.module';
import { reduce } from 'lodash';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SidebarModule } from 'ng-sidebar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@app/../environments/environment';
import { AppUpdateService } from '@app/shared/services/app-update.service';

export function translatePipeFactoryCreator(translateService: TranslateService) {
  return (detector: ChangeDetectorRef) => new TranslatePipe(translateService, detector);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CpTranslateLoader,
        deps: [HttpClient]
      }
    }),

    NgxValidationErrorsModule.forRoot({
      defaultContext: 'app.general',
    }),

    NgCircleProgressModule.forRoot({
      backgroundGradient: true,
      backgroundPadding: 50,
      radius: 117,
      space: -7,
      outerStrokeWidth: 7,
      outerStrokeColor: '#76C2AF',
      innerStrokeColor: '#eee',
      innerStrokeWidth: 7,
      imageHeight: 30,
      imageWidth: 30,
      backgroundColor: 'red',
      animationDuration: 1100,
      showBackground: false
    }),

    PerfectScrollbarModule,
    FlexLayoutModule,

    SidebarModule.forRoot(),
     ServiceWorkerModule.register('ngsw-worker.js', {
       enabled: environment.production,
       // Register the ServiceWorker as soon as the app is stable
       // or after 30 seconds (whichever comes first).
       registrationStrategy: 'registerWhenStable:30000'
     }),
  ],

  providers: [
    AuthService,
    AuthGuard,
    DataResolver,
    GlobalService,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true
    },

    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    {
      provide: MESSAGES_PIPE_FACTORY_TOKEN,
      useFactory: translatePipeFactoryCreator,
      deps: [TranslateService]
     },

     {
      provide: MESSAGES_PROVIDER,
      useExisting: TranslateService
     },

     AppUpdateService,

  ],

  exports: [
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    injector: Injector,
    faConfig: FaConfig,
    private authService: AuthService,
  ) {
    if (window['Cypress']) {
      window['AuthService'] = authService;
    }
    setAppInjector(injector);
  }
}
