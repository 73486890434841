<!--Footer Starts-->
<footer class="footer mt-3">
  <p class="copyright clearfix text-muted m-0 text-left">
    Copyright &copy; {{currentDate | date: 'yyyy'}}
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
    <!-- Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
      href="https://pilot.com.hk">PILOT SIMPLE SOFTWARE</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span> -->
  </p>
</footer>
<!--Footer Ends-->
