import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class LangService {

  currentLanguage = '';
  selectedLanguageText = '';
  selectedLanguageFlag = '';

  constructor(
    public translate: TranslateService,
    private router: Router,
    public util: UtilService,
  ) {
    this.setLanguageDetails();

    const lang = util.getUrlParam2('lang');
    if (lang) {
      this.setLanguageDetails(lang);
    }
  }

  setLanguageDetails(language?: string) {
    if (!language) {
      language = localStorage.getItem('language');
      language = language ? language : 'en';
    }

    localStorage.setItem('language', language);

    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/en.png';
    } else if (language === 'fr') {
      this.selectedLanguageText = 'Français';
      this.selectedLanguageFlag = './assets/img/flags/fr.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Español';
      this.selectedLanguageFlag = './assets/img/flags/sp.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Português';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    }

    this.currentLanguage = language;

    this.translate.use(language);
  }
}
