import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  deferredPrompt: any;
  public showInstallButton: boolean = false;

  constructor(
    // private changeDetectRef: ChangeDetectorRef,
  ) {
    this.initPwaPrompt();
  }

  public initPwaPrompt() {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      console.log('beforeinstallprompt...');
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = true;

      // this.changeDetectRef.detectChanges();
    });
  }

  public addToHome() {
    this.deferredPrompt.prompt();

    this.deferredPrompt.userChoice
      .then((choiceResult: { outcome: string; }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('installed...');
        } else {
          console.log('not installed...');
        }
        this.deferredPrompt = null;
      });
  }

  //   // Detects if device is on iOS
  // const isIos = () => {
  //     const userAgent = window.navigator.userAgent.toLowerCase();
  //     return /iphone|ipad|ipod/.test( userAgent );
  //   }
  //   // Detects if device is in standalone mode
  //   const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  //   // Checks if should display install popup notification:
  //   if (isIos() && !isInStandaloneMode()) {
  //     this.setState({ showInstallMessage: true });
  //   }
}
