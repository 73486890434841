<ng-template let-course="course" let-sectionCurrent="sectionCurrent" #sectionCurrentTpl>
  <div class="header-panel2 rounded shadow-sm">
  <h5>{{sectionCurrent.title}}</h5>
    <div class="row">
      <div class="col-8 col-md-5">
        <app-course-progress-bar height="25" [completion_level]="sectionCurrent.section_completion_level">
        </app-course-progress-bar>
      </div>
    </div>
  </div>

  <div class="topic rounded mt-4">
    <div class="p-1">
      <span class="h5">{{'TOPICS' | translate}}</span>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md-6 col-xl-4 content" data-cy-content
        *ngFor="let content of sectionCurrent.contents; index as i">
        <div class="card overflow-hidden m-1">
          <div class="card-header border-bottom">
            <div class="text-center">
              <ng-container *ngIf="!content.locked; else elseBlockContentImg">
                <a [routerLink]="['/course/learn/', course.customer_course_id, 'section', sectionCurrent.course_section_id,
                  'content', content.course_content_id]" data-cy="goto-content">
                  <ng-container *ngTemplateOutlet="elseBlockContentImg"></ng-container>
                </a>
              </ng-container>
              <ng-template #elseBlockContentImg>
                <ng-container *ngIf="content.has_picture else elseBlockNoContentImg">
                  <img class="img-topic rounded" src="{{content?.picture?.file_normal}}">
                </ng-container>
                <ng-template #elseBlockNoContentImg>
                  <img class="img-topic" src="assets/img/icons/picture.png">
                </ng-template>
              </ng-template>
            </div>

            <div class="header-content my-2">
              <a [routerLink]="['/course/learn/', course.customer_course_id, 'section', sectionCurrent.course_section_id,
              'content', content.course_content_id]">
                {{content.sort_order}}: {{content.title}}
              </a>
            </div>
          </div>
          <div class="card-body">
            <p class="card-text" innerHTML="{{content.caption | truncate: 150}}"></p>
          </div>

          <div class="card-footer text-center" *ngIf="courseService.getContentInfo(content); let contentInfo">
            <fa-icon [icon]="contentInfo.icon" class="fa-2x" [style.color]="contentInfo.color"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!----------- Template ----------->
<div class="mb-4">
  <ng-container *ngTemplateOutlet="sectionCurrentTpl; context:{course: course, sectionCurrent: sectionCurrent}">
  </ng-container>
</div>