import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('@app/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'course',
    loadChildren: () => import('@app/pages/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'account',
    loadChildren: () => import('@app/pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'company',
    loadChildren: () => import('@app/pages/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'training',
    loadChildren: () => import('@app/pages/training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'employee-training',
    loadChildren: () => import('@app/pages/employee-training/employee-training.module').then(m => m.EmployeeTrainingModule)
  },
  {
    path: 'community',
    loadChildren: () => import('@app/pages/community/community.module').then(m => m.CommunityModule)
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import('@app/pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule)
  },
  {
    path: 'message',
    loadChildren: () => import('@app/pages/message/message.module').then(m => m.MessageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('@app/pages/report/report.module').then(m => m.ReportModule)
  },
];
