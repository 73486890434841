import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { LEARN_ROUTES } from './shared/routes/learn.routes';

const appRoutes: Routes = [
  {
    path: '',
    // redirectTo: 'dashboard',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: LEARN_ROUTES,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: '',
    component: ContentLayoutComponent,
    children: CONTENT_ROUTES
  },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
