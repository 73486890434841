import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
const routesInfo: RouteInfo[] = [];

routesInfo.push(
  new RouteInfo({ path: '/dashboard', title: 'Dashboard', icon: 'ft-grid'})
);

let submenu = [
  new RouteInfo({path: '/account/profile', title: 'Profile', icon: 'ft-user-check submenu-icon'}),
  new RouteInfo({path: '/account/payments', title: 'Payments', icon: 'ft-dollar-sign submenu-icon'}),
  new RouteInfo({path: '/account/certificate/list', title: 'Certificates', icon: 'ft-award submenu-icon'}),
];

routesInfo.push(
  new RouteInfo({
    name: 'account', path: '/account/profile', title: 'Account', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '',
    isExternalLink: false, submenu: submenu
 })
);

// -------------------------- //
submenu = [
  new RouteInfo({path: '/company/contact', title: 'Contacts',
    icon: 'ft-users submenu-icon', isAdminMenu: true}),
  new RouteInfo({path: '/company/training-setting/list', rootPath: '/company/training-setting',
    title: 'Training Setting', icon: 'ft-book submenu-icon', isAdminMenu: true}),
  new RouteInfo({path: '/company/course-setting/list', rootPath: '/company/course-setting',
    title: 'Course Setting', icon: 'ft-book-open submenu-icon', isAdminMenu: true}),
];

routesInfo.push(
  new RouteInfo({
    name: 'company', path: '/company/contact', title: 'Company Settings', icon: 'ft-settings',
    class: 'has-sub', isAdminMenu: true, submenu: submenu})
);

// -------------------------- //
routesInfo.push(
  new RouteInfo({path: '/training/list', rootPath: '/training', title: 'My Trainings', icon: 'ft-book',
    forCompanyCustomer: true}),
  new RouteInfo({path: '/course/list', rootPath: '/course', title: 'My Courses', icon: 'ft-book-open'}),
  new RouteInfo({path: '/employee-training/list', rootPath: '/employee-training',
    title: 'Behavioural Analysis Test', icon: 'ft-bar-chart-2', forEmpCapAssemnt: true }),
  new RouteInfo({path: '/community/list', rootPath: '/community', title: 'Community', icon: 'ft-users',
    name: 'community', forCompanyCustomer: true}),
  new RouteInfo({path: '/knowledge-base/list', rootPath: '/knowledge-base', title: 'Knowledge Base', icon: 'ft-info'}),
  new RouteInfo({path: '/message/list', rootPath: '/message', title: 'Messages', icon: 'ft-message-square'}),
  new RouteInfo({path: '/report/list', rootPath: '/report', title: 'Reports', icon: 'ft-layers'}),
);


export const ROUTES = routesInfo;
